import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./shared/Layout";
import Home from "./pages/home/Home";
import NotFound from "./pages/NotFound";
import CGU from "./pages/cgu-cgv/CGU";
import MentionsLegales from "./pages/MentionsLegales";
import ScrollToTop from "./helpers/ScrollToTop";
import { VirtualTour } from "./pages/virtual-tour/VirtualTour";
import ProfessionalPhotography from "./pages/professional-photography/ProfessionalPhotography";
import HomeStaging from "./pages/homestaging/HomeStaging";
import Quiz from "./pages/quiz/Quiz";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import PlanView from "./pages/plans/PlanView";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="visite-virtuelle" element={<VirtualTour />} />
          <Route path="photo-pro" element={<ProfessionalPhotography />} />
          <Route path="homestaging" element={<HomeStaging />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="a-propos" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="cgu" element={<CGU />} />
          <Route path="mentions-legales" element={<MentionsLegales />} />
          <Route path="*" element={<NotFound />} />
          <Route path="formule/:id" element={<PlanView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
