import { twMerge } from "tailwind-merge";

export default function HeroButton({ text, link, classNames }) {
  function scrollTo(link) {
    const element = document.getElementById(link);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  return (
    <div>
      <button
        className={twMerge(
          "block w-full rounded-full border border-labelleoffre-secondary/80 bg-labelleoffre-tertiary-light px-12 py-3 font-Josefin text-lg font-medium tracking-normal text-labelleoffre-secondary shadow transition-colors hover:bg-labelleoffre-tertiary focus:outline-none focus:ring active:bg-labelleoffre-secondary sm:w-auto",
          classNames,
        )}
        onClick={() => scrollTo(link)}
      >
        {text}
      </button>
    </div>
  );
}
