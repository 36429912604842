import { useState } from "react";
import { FaqData } from "./FaqData";
import { useSpring, animated } from "@react-spring/web";
import "./FaqComponent.css";
import { twMerge } from "tailwind-merge";

export default function FaqComponent() {
  const [viewMore, setViewMore] = useState(false);
  const [extraQuestionsDivStyle] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: viewMore ? 1 : 0 },
      config: {
        duration: 700,
      },
    }),
    [viewMore],
  );
  function handleViewMore() {
    setViewMore(!viewMore);
  }
  function PlusIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 size-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  }
  function MinusIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 size-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  }

  function FaqItem({ q, styleProps }) {
    const [isOpen, toggle] = useState(false);
    const [props] = useSpring(
      () => ({
        from: { opacity: 0 },
        to: { opacity: isOpen ? 1 : 0 },
        config: {
          duration: 700,
        },
      }),
      [isOpen],
    );

    return (
      <animated.div className="max-w-screen-lg p-6" style={styleProps}>
        <div
          className="flex cursor-pointer items-center justify-between gap-1.5 text-labelleoffre-secondary"
          onClick={() => toggle(!isOpen)}
        >
          <h2 className="text-lg font-medium text-labelleoffre-secondary">
            {q.title}
          </h2>
          <span className="relative size-5 shrink-0">
            {!isOpen ? <PlusIcon /> : <MinusIcon />}
          </span>
        </div>
        {isOpen && (
          <animated.div
            className="mt-4 font-light leading-relaxed text-gray-800"
            style={props}
          >
            {q.answer}
          </animated.div>
        )}
      </animated.div>
    );
  }

  function ViewMoreButton({ viewMore, handler }) {
    return (
      <>
        <div className=" mt-8 text-center">
          <button
            onClick={handler}
            className="cursor-pointer rounded-full bg-labelleoffre-tertiary px-12 py-3 text-sm font-medium text-white shadow transition-colors hover:bg-labelleoffre-secondary focus:outline-none focus:ring active:bg-labelleoffre-secondary"
          >
            {viewMore === true ? "En voir moins" : "En voir plus"}
            &nbsp;&nbsp;
            <i
              className={twMerge(
                "fa-solid",
                viewMore === true ? "fa-arrow-up" : "fa-arrow-down",
              )}
            />
          </button>
        </div>
      </>
    );
  }

  return (
    <section id="section-faq" className="pb-10">
      <div className="mx-auto w-full rounded-3xl bg-labelleoffre-tertiary-light p-4 sm:w-3/4">
        <h1 className="pb-8 pt-4 text-center font-bold tracking-tighter text-labelleoffre-secondary">
          Des questions&nbsp;?
        </h1>

        <p className="text-center font-light">
          Vous cherchez des réponses à vos questions&nbsp;? Vous êtes au bon
          endroit&nbsp;!
        </p>
        <p className="text-center font-light">
          En plus, chez nous, on aime bien expliquer les choses, pour que tout
          soit clair pour vous&nbsp;!
        </p>

        <div className="mt-10 flex flex-col items-center justify-center gap-6">
          <div className="divide-y divide-labelleoffre-tertiary/20 rounded-xl border border-labelleoffre-tertiary/30 bg-white">
            {FaqData.map((q, i) => (
              <>
                {!q.isExtra && <FaqItem q={q} />}
                {viewMore && q.isExtra && (
                  <FaqItem q={q} styleProps={extraQuestionsDivStyle} />
                )}
              </>
            ))}
          </div>
        </div>

        <ViewMoreButton viewMore={viewMore} handler={handleViewMore} />

        <p className="py-10 text-center text-sm font-light text-labelleoffre-secondary">
          Vous ne trouvez pas de réponse à votre question ? N'hésitez pas à{" "}
          <a
            href="/contact"
            className="font-medium text-labelleoffre-tertiary hover:text-labelleoffre-secondary"
          >
            nous contacter
          </a>{" "}
          directement !
        </p>
      </div>
    </section>
  );
}
