export const questions = [
  {
    id: 1,
    text: (
      <>
        Comment évalueriez-vous votre connaissance du marché immobilier
        local&nbsp;?
      </>
    ),
    choices: [
      "Expert, je suis au courant de chaque fluctuation.",
      "J'ai quelques notions, mais je pourrais en apprendre davantage.",
      "Je suis un peu perdu, c'est comme naviguer en eaux inconnues.",
    ],
  },
  {
    id: 2,
    text: (
      <>Avez-vous une idée précise de la valeur de votre propriété&nbsp;?</>
    ),
    choices: [
      "Oui, j'ai fait des recherches approfondies et j'ai une estimation solide.",
      "J'ai une idée approximative, mais je suis ouvert à une évaluation professionnelle.",
      "Non, je compte sur la chance pour fixer le prix.",
    ],
  },

  {
    id: 3,
    text: (
      <>
        Êtes-vous à l'aise pour négocier directement avec les acheteurs
        potentiels&nbsp;?
      </>
    ),
    choices: [
      "Absolument, je suis un négociateur né !",
      "Je peux m'en sortir, mais je pourrais bénéficier de conseils supplémentaires.",
      "Les négociations me donnent des sueurs froides, j'ai besoin d'un intermédiaire.",
    ],
  },
  {
    id: 4,
    text: <>Avez-vous déjà vendu une propriété auparavant&nbsp;?</>,
    choices: [
      "Oui, j'ai de l'expérience dans le domaine.",
      "Non, c'est ma première fois.",
      "J'ai essayé une fois, mais c'était un désastre.",
    ],
  },
  {
    id: 5,
    text: (
      <>
        Êtes-vous prêt à consacrer du temps à la préparation de votre propriété
        pour la vente&nbsp;?
      </>
    ),
    choices: [
      "Bien sûr, je suis prêt à faire ce qu'il faut pour que ma maison brille !",
      "Je peux m'en occuper dans la mesure du possible.",
      "Je préfère laisser les choses telles quelles et espérer le meilleur.",
    ],
  },
  {
    id: 6,
    text: (
      <>
        Êtes-vous familier avec les étapes nécessaires pour vendre une
        propriété&nbsp;?
      </>
    ),
    choices: [
      "Oui, j'ai fait mes devoirs et je suis prêt à agir.",
      "J'ai quelques idées, mais j'aimerais en savoir plus.",
      "Pas vraiment, je suis un peu dans le flou.",
    ],
  },
  {
    id: 7,
    text: (
      <>
        Êtes-vous ouvert à recevoir des conseils professionnels pour maximiser
        la valeur de votre propriété&nbsp;?
      </>
    ),
    choices: [
      "Absolument, je veux tirer le meilleur parti de ma vente.",
      "Peut-être, si cela peut rendre le processus plus facile.",
      "Non, je préfère me débrouiller seul.",
    ],
  },
  {
    id: 8,
    text: (
      <>
        Êtes-vous prêt à investir dans des efforts marketing pour attirer les
        acheteurs potentiels&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à investir dans la publicité pour vendre ma propriété.",
      "Je pourrais envisager quelques dépenses, mais pas trop.",
      "Non, je compte sur le bouche-à-oreille pour trouver un acheteur.",
    ],
  },
  {
    id: 9,
    text: (
      <>
        Êtes-vous prêt à gérer les visites des acheteurs potentiels
        vous-même&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à accueillir les acheteurs et à leur faire visiter ma propriété.",
      "Je pourrais le faire, mais j'aimerais avoir des conseils sur la manière de procéder.",
      "Non, je préfère laisser cette tâche à quelqu'un d'autre.",
    ],
  },
  {
    id: 10,
    text: (
      <>
        Êtes-vous prêt à être disponible pour répondre aux questions des
        acheteurs potentiels à tout moment&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à répondre à toutes les questions pour faciliter la vente.",
      "Je peux essayer d'être disponible, mais cela pourrait être difficile.",
      "Non, je préfère avoir quelqu'un d'autre pour gérer les questions des acheteurs.",
    ],
  },
  {
    id: 11,
    text: (
      <>
        Avez-vous une idée de la durée moyenne de vente d'une propriété dans
        votre région&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis bien informé sur les tendances du marché immobilier local.",
      "J'ai une estimation approximative, mais je pourrais en savoir plus.",
      "Non, je n'ai aucune idée de combien de temps cela pourrait prendre.",
    ],
  },
  {
    id: 12,
    text: (
      <>
        Êtes-vous prêt à gérer les offres et contre-offres des acheteurs
        potentiels&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à négocier pour obtenir le meilleur prix pour ma propriété.",
      "Je pourrais le faire, mais j'aimerais avoir des conseils sur la meilleure stratégie de négociation.",
      "Non, je préfère laisser cette partie à quelqu'un d'autre.",
    ],
  },
  {
    id: 13,
    text: (
      <>
        Êtes-vous familiarisé avec les formalités administratives liées à la
        vente d'une propriété&nbsp;?
      </>
    ),
    choices: [
      "Oui, je connais les documents nécessaires et les démarches à suivre.",
      "J'ai quelques notions, mais je pourrais avoir besoin d'un peu d'aide.",
      "Non, les formalités administratives me donnent des maux de tête.",
    ],
  },
  {
    id: 14,
    text: (
      <>
        Êtes-vous prêt à investir dans des réparations ou des rénovations pour
        améliorer l'attrait de votre propriété&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à investir pour maximiser la valeur de ma propriété.",
      "Je pourrais envisager quelques améliorations, mais je préférerais ne pas dépenser trop.",
      "Non, je préfère ne pas dépenser d'argent supplémentaire avant la vente.",
    ],
  },
  {
    id: 15,
    text: (
      <>
        Êtes-vous prêt à prendre des photos de haute qualité de votre propriété
        pour les annonces&nbsp;?
      </>
    ),
    choices: [
      "Oui, je suis prêt à investir dans des photos professionnelles pour mettre en valeur ma propriété.",
      "Je pourrais le faire moi-même, mais j'aimerais des conseils sur la meilleure façon de les prendre.",
      "Non, je pense que des photos floues prises avec mon téléphone suffiront.",
    ],
  },
];
