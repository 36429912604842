import { useSpring, animated } from "@react-spring/web";
import Logo from "./Logo";
import { useState } from "react";

const FlyoutLink = ({ children, href, FlyoutContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const springs = useSpring({
    from: {
      height: 0,
      opacity: 0,
      display: "none",
    },
    to: {
      height: !isOpen ? 0 : "fit-content",
      opacity: isOpen ? 1 : 0,
      display: "flex",
    },
  });

  const show = isOpen && FlyoutContent;
  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className="group relative h-fit w-fit cursor-pointer p-5"
    >
      <a
        href={href}
        className="relative font-light text-labelleoffre-tertiary hover:text-labelleoffre-secondary"
      >
        {children}
        <span
          style={{ transform: isOpen ? "scaleX(1)" : "scaleX(0)" }}
          className="absolute -bottom-2 -left-2 -right-2 h-0.5 origin-left rounded-full bg-labelleoffre-tertiary transition-transform duration-300 ease-out"
        />
      </a>
      {show && (
        <animated.div
          style={springs}
          className="absolute left-1/2 top-16 -translate-x-1/2 rounded-b-md bg-white text-labelleoffre-secondary"
        >
          {/* transparent "bridge" to allow user to go to the subitems div */}
          <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />

          {/* triangle */}
          {/* <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" /> */}

          <div className="w-64 p-2 shadow-xl">
            {FlyoutContent.map((item) => (
              <div className="rounded-sm p-4 transition-colors hover:bg-labelleoffre-tertiary hover:text-white">
                <a className="font-light" href={item.url}>
                  {item.name}
                </a>
              </div>
            ))}
          </div>
        </animated.div>
      )}
    </div>
  );
};
export default function DesktopMenu({ routes }) {
  return (
    <nav className="fixed z-10 hidden w-full bg-labelleoffre-primary shadow-sm md:block">
      <div className="mx-auto px-4 sm:max-w-screen-sm md:max-w-7xl">
        <div className="hidden h-16 justify-between md:flex">
          <Logo />
          <div className="flex">
            {routes.map((route) => (
              <FlyoutLink
                href={route.url}
                FlyoutContent={route.children}
                key={route.id}
              >
                {route.name}
              </FlyoutLink>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}
