import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { twMerge } from "tailwind-merge";
import { useForm } from "react-hook-form";

export default function Contact() {
  function SubmitButton({ text, disabled, loading }) {
    return (
      <div className="mt-4">
        <button
          type="submit"
          className={twMerge(
            "inline-flex w-full rounded-full bg-labelleoffre-secondary px-5 py-3 text-base font-light text-white transition-colors hover:bg-labelleoffre-tertiary sm:w-auto",
            disabled
              ? "cursor-not-allowed bg-labelleoffre-tertiary-light text-slate-400 hover:bg-labelleoffre-tertiary-light"
              : "",
          )}
        >
          {loading ? (
            <>
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Chargement en cours...
            </>
          ) : (
            text
          )}
        </button>
      </div>
    );
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [formResult, setFormResult] = useState(undefined);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const setFormErrorAndHide = (error) => {
    setFormResult(error);
    setTimeout(() => setFormResult(undefined), 3500);
  };

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const sendEmail = (data) => {
    if (!tosAccepted) {
      setFormErrorAndHide("Veuillez accepter les conditions d'utilisation.");
      return;
    }

    console.log(data);

    setLoading(true);

    console.log(formRef.current);

    emailjs
      .sendForm(serviceId, templateId, formRef.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          setFormResult(true);
          setLoading(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setLoading(false);
          setFormErrorAndHide(error);
        },
      );
  };

  const formRef = useRef();

  return (
    <section className="bg-white pt-2 md:px-2 md:pb-16 md:pt-24">
      <div className="mx-auto bg-labelleoffre-tertiary md:max-w-screen-xl md:rounded-3xl">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <h1 className="pb-4 tracking-tighter text-labelleoffre-secondary">
                Vous souhaitez être recontacté par nos équipes&nbsp;?
              </h1>
              <p className="max-w-xl text-lg font-light">
                Vous pouvez utiliser le formulaire ci-contre pour nous faire
                part de vos demandes. Nous vous recontacterons dans les plus
                brefs délais.
              </p>
            </div>

            <div className="rounded-2xl bg-white p-6 shadow-lg lg:col-span-3 lg:p-12">
              {formResult === undefined || formResult !== true ? (
                <form
                  className="group space-y-4"
                  onSubmit={handleSubmit(sendEmail)}
                  ref={formRef}
                >
                  <div className="">
                    <label className="sr-only" htmlFor="name">
                      Nom
                    </label>
                    <input
                      className="w-full rounded-2xl border border-labelleoffre-tertiary/80 p-3 text-base font-light"
                      placeholder="Nom et prénom"
                      {...register("name", { required: true, minLength: 2 })}
                    />
                    {errors.name && (
                      <p className="p-2 text-sm text-error/60">
                        <i className="fa-solid fa-warning" />
                        &nbsp;Ce champ est obligatoire.
                      </p>
                    )}
                  </div>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="sr-only" htmlFor="email">
                        Adresse e-mail
                      </label>
                      <input
                        className="w-full rounded-2xl border border-labelleoffre-tertiary/80 p-3 text-base font-light"
                        placeholder="Adresse e-mail"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
                        })}
                      />
                      {errors.email && (
                        <p className="p-2 text-sm text-error/60">
                          <i className="fa-solid fa-warning" />
                          &nbsp;Veuillez saisir une adresse e-mail valide.
                        </p>
                      )}
                    </div>

                    <div>
                      <label className="sr-only" htmlFor="phone">
                        Téléphone
                      </label>
                      <input
                        className="w-full rounded-2xl border border-labelleoffre-tertiary/80 p-3 text-base font-light"
                        placeholder="Téléphone"
                        {...register("phone", {
                          required: true,
                          pattern: /^[0-9 /+]*$/,
                        })}
                      />
                      {errors.phone && (
                        <p className="p-2 text-sm text-error/60">
                          <i className="fa-solid fa-warning" />
                          &nbsp;Veuillez saisir un numéro de téléphone valide.
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-2">
                      <div className="w-full">
                        <input
                          value="achat"
                          type="radio"
                          {...register("plan", { required: true })}
                          id="achat"
                          className="peer hidden"
                        />
                        <label
                          htmlFor="achat"
                          className="block w-full cursor-pointer select-none rounded-2xl border border-labelleoffre-tertiary/80 p-3 font-light text-gray-600 hover:border-labelleoffre-secondary peer-checked:border-labelleoffre-secondary/40 peer-checked:bg-labelleoffre-tertiary peer-checked:text-white"
                        >
                          Achat
                        </label>
                      </div>

                      <div className="w-full">
                        <input
                          value="vente"
                          type="radio"
                          {...register("plan", { required: true })}
                          id="vente"
                          className="peer hidden"
                        />
                        <label
                          htmlFor="vente"
                          className="block w-full cursor-pointer select-none rounded-2xl border border-labelleoffre-tertiary/80 p-3 font-light text-gray-600 hover:border-labelleoffre-secondary peer-checked:border-labelleoffre-secondary/40 peer-checked:bg-labelleoffre-tertiary peer-checked:text-white"
                        >
                          Vente
                        </label>
                      </div>

                      <div className="w-full">
                        <input
                          value="presta"
                          type="radio"
                          {...register("plan", { required: true })}
                          id="presta"
                          className="peer hidden"
                        />
                        <label
                          htmlFor="presta"
                          className="block w-full cursor-pointer select-none rounded-2xl border border-labelleoffre-tertiary/80 p-3 font-light text-gray-600 hover:border-labelleoffre-secondary peer-checked:border-labelleoffre-secondary/40 peer-checked:bg-labelleoffre-tertiary peer-checked:text-white"
                        >
                          Prestation
                        </label>
                      </div>
                    </div>
                    {errors.plan && (
                      <p className="p-2 text-sm text-error/60">
                        <i className="fa-solid fa-warning" />
                        &nbsp;Vous devez sélectionner une option.
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="description">
                      Expliquez nous en quelques mots votre demande ou votre
                      projet...
                    </label>
                    <textarea
                      className="w-full rounded-2xl border-labelleoffre-tertiary/80 p-3 text-base font-light"
                      placeholder="Expliquez nous en quelques mots votre demande ou votre projet..."
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <p className="p-2 text-sm text-error/60">
                        <i className="fa-solid fa-warning" />
                        &nbsp;Merci de préciser votre demande.
                      </p>
                    )}
                  </div>

                  <div className="">
                    <label
                      htmlFor="accept-tos"
                      className="select-none"
                      onClick={() => setTosAccepted(!tosAccepted)}
                    >
                      <div className="flex cursor-pointer items-start justify-start gap-4 font-light">
                        <input
                          type="checkbox"
                          className="size-6 rounded border-gray-300 text-labelleoffre-tertiary"
                          placeholder="J'accepte les conditions d'utilisation"
                          checked={tosAccepted}
                          onChange={() => setTosAccepted(!tosAccepted)}
                          // {...register("accept-tos", { required: true })}
                        />
                        J'accepte les conditions d'utilisation
                      </div>
                    </label>
                  </div>

                  <SubmitButton
                    id="submit"
                    text="J'envoie ma demande"
                    disabled={!tosAccepted}
                    loading={loading}
                  />
                </form>
              ) : (
                <>
                  <div className="text-lg font-light md:min-h-[500px]">
                    <h1 className="pb-4 text-labelleoffre-secondary">
                      Formulaire envoyé
                    </h1>
                    <p>Merci pour votre message.</p>
                    <p>
                      Notre équipe vous recontactera dans les plus brefs délais.
                    </p>
                  </div>
                </>
              )}
              {formResult !== true && formResult !== undefined && (
                <>
                  <div className="my-4 rounded-xl bg-red-300 p-4">
                    <h2>Erreur</h2>
                    <p>{formResult}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
