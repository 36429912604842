export const plansData = [
  {
    id: 'sur-mesure',
    order: 1,
    name: `Coaching sur mesure`,
    subtitle: `Votre coaching immobilier sur mesure`,
    discretion: "Pour vous accompagner dans toutes les étapes de votre projet immobilier.",
    discretionLong: "Notre offre de coaching personnalisé est faite pour vous accompagner dans toutes les étapes de votre projet immobilier. Que ce soit par téléphone ou en personne dans un rayon de 20 km autour de Nîmes, bénéficiez d'une heure de conseils experts pour seulement 60€. Pour plus de praticité, nous nous déplaçons également dans un rayon de 21 à 50 km, moyennant un tarif de 85€/heure.",
    price: 60,
    isPriceStartingFrom: true,
    mainFeatures: [
      { title: "Cahier des charges", text: "Élaboration d'un cahier des charges pour la recherche d'un bien" },
      { title: "Focalisé sur vos critères", text: "Structuration et méthodologie de la recherche selon vos critères" },
      { title: "Conseil d'aménagement", text: "Conseils pour l'aménagement ou la rénovation" },
      { title: "Analyse de votre situation", text: "Analyse de votre situation financière en vue de votre projet d'achat" },
    ],
    features: [
      `Élaboration d'un cahier des charges pour la recherche d'un bien`,
      `Structuration et méthodologie de la recherche selon vos critères`,
      `Conseils pour l'aménagement ou la rénovation`,
      `Analyse de votre situation financière en vue de votre projet d'achat`,
      `Diagnostic pour identifier les problématiques liées à la vente d'un bien`,
      `Vérification de la viabilité et de la rentabilité de votre projet immobilier`,
    ],
    detail: `Votre session de coaching personnalisée selon vos besoins spécifiques.`,
    highlighted: false,
    background: "/img/plans/tinmay-yu-CZHKfayP2Y4-unsplash.jpg",
    paymentConditions: ["Le règlement en totalité doit être effectué lors de la signature du contrat."]
  },
  {
    id: 'premium',
    order: 3,
    name: `Pack premium`,
    subtitle: `Maximisez vos opportunités`,
    subtitleLong: "Valorisez votre bien immobilier, économisez du temps et maximisez vos opportunités",
    discretion: `Pour vous faire gagner du temps tout en augmentant votre potentiel d'acquéreurs.`,
    discretionLong: "Votre bien immobilier mérite d'être présenté de manière exceptionnelle, sans vous demander un temps précieux. Avec notre Pack Premium, nous mettons en valeur votre propriété, vous faisant gagner du temps tout en augmentant votre potentiel d'acquéreurs. Grâce à notre visite virtuelle immersive et réaliste, vous évitez les visites inutiles et attirez uniquement les acheteurs sérieux. Profitez également de notre évaluation précise et de nos photos professionnelles pour maximiser l'attrait de votre bien.",
    price: 898,
    mainFeatures: [
      {
        title: "Gagnez un temps précieux",
        text: "Fini les visites non sérieuses, seuls les acheteurs vraiment intéressés prendront un rendez-vous physique."
      },
      {
        title: "Rapport Complet",
        text: "Recevez un rapport détaillé de 15 pages minimum, mettant en avant l'unicité de votre propriété."
      },
      {
        title: "Photos de qualité professionnelle",
        text: "Capturez l'essence même de votre bien avec un shooting photo réalisé par nos experts."
      },
      {
        title: "Visite Virtuelle Immersive",
        text: "Offrez à vos potentiels acheteurs une exploration immersive de votre propriété."
      },
    ],
    features: [
      `Évaluation précise`,
      `Rapport Complet`,
      `Photos de qualité professionnelle`,
      `Visite Virtuelle Immersive`,
      `Plan 2D Détaillé`,
      `Rédaction Professionnelle de l'Annonce Immobilière**`,
      `Support de Vente Inclus`,
      `Fiche de Visite Personnalisée`,
      `1 heure de coaching incluse`,
    ],
    detailedFeatures: [
      `Évaluation précise : Obtenez une estimation précise de la valeur de votre bien, établie par des professionnels expérimentés.`,
      `Rapport Complet : Recevez un rapport détaillé de 15 pages minimum, mettant en avant les caractéristiques uniques de votre propriété.`,
      `Photos de qualité professionnelle : Capturez l'essence même de votre bien avec un shooting photo réalisé par nos experts`,
      `Nous allons sublimer les photos prises pour présenter le bien immobilier dans toute sa splendeur, en mettant en valeur ses caractéristiques et son attrait visuel.`,
      `Visite Virtuelle Immersive : Offrez à vos potentiels acheteurs une exploration immersive de votre propriété, réduisant ainsi les visites en personne et optimisant votre temps.`,
      `Plan 2D Détaillé : Obtenez un plan détaillé de votre logement pour une présentation claire et attrayante.`,
      `Rédaction Professionnelle de l'Annonce Immobilière** : Mettez en valeur les atouts de votre bien grâce à une annonce rédigée de manière professionnelle, attirant l'attention des acheteurs potentiels.`,
      `Support de Vente Inclus`,
      `Fiche de Visite Personnalisée : Impressionnez les visiteurs avec une fiche détaillée, fournissant toutes les informations nécessaires lors des visites.`,
      `1 heure de coaching incluse : Profitez d'une session de coaching personnalisée pour maximiser l'impact de la présentation de votre bien.`,

    ],
    options: {
      warning: "Des options supplémentaires sont disponibles avec frais additionnels.",
      title: "Options de home staging virtuel",
      items: [
        {
          name: "Photos intérieures",
          price: "149"
        },
        {
          name: "Photos de la pièce de vie",
          price: "169"
        },
        {
          name: "Photos extérieures",
          price: "199"
        },
        {
          name: "Visite du logement en home staging virtuel 3D",
          price: "10",
          priceMore: "prix au m²",
          rule: "Surface minimale de 25 m²"
        },
      ]
    },
    detail: `Avec notre Pack Premium Propriétaire, libérez-vous des contraintes de la présentation de votre bien et concentrez-vous sur la réalisation de votre projet immobilier dans les meilleures conditions.`,
    highlighted: false,
    background: "/img/plans/sumner-mahaffey-7Y0NshQLohk-unsplash.jpg",
    paymentConditions: [
      "Un acompte de 30% sera demandé lors de la signature du contrat (sauf pour les règlements en carte bancaire).",
      "Le solde restant sera à régler une fois la prestation Labelleoffre réalisée."
    ]
  }, {
    id: "serenite",
    order: 2,
    name: `Formule Sérénité`,
    subtitle: `La vente immobilière sans contraintes`,
    subtitleLong: "Vendez en toute confiance avec notre accompagnement personnalisé pour une vente immobilière facile et optimale",
    discretion: `Pour une expérience de vente fluide et sans tracas avec notre formule tout-en-un.`,
    // discretion: `Avec notre formule tout-en-un, nous vous offrons une expérience de vente fluide et sans tracas. `,
    discretionLong: "Confiez-vous la vente de votre bien en toute tranquillité. Avec notre formule tout-en-un, nous vous offrons une expérience de vente fluide et sans tracas. Découvrez les avantages de notre formule sérénité ; une liberté totale: pas de mandat, pas de commission cachée, pas d'exclusivité. Vous gardez le contrôle à chaque étape du processus.",
    price: 3498,
    mainFeatures: [
      { title: "Pack premium propriétaire", text: "La formule Sérénité contient tout ce que propose le pack Premium." },
      { title: "Stratégie personnalisée", text: "Élaboration d'une stratégie de vente personnalisée." },
      { title: "Ciblage des profils", text: "Ciblage des profils d'acheteurs potentiels" },
      { title: "Petites annonces", text: "Publication sur des sites de renom tels que Leboncoin et Seloger" },
    ],
    features: [
      `Pack premium propriétaire `,
      `Votre stratégie de vente personnalisée.`,
      `Ciblage des profils d'acheteurs potentiels`,
      `Publication sur Leboncoin et Seloger`,
      `Évaluation approfondie des capacités financières des acquéreurs`,
      `Esquisse 2D améliorée du logement`,
      `Home staging virtuel`,
      `Mise en situation pour visites`,
      `Adaptation de l'attitude professionnelle lors de la négociation/commercialisation`,
      `Préparation du dossier Notaire`,
      `Mise en relation avec nos partenaires`,
      `Suivi et assistance tout au long du projet`,
    ],
    detailedFeatures: [
      `Pack premium propriétaire `,
      `Élaboration d'une stratégie de vente personnalisée.`,
      `Ciblage des profils d'acheteurs potentiels`,
      `Publication sur des sites de renom tels que Leboncoin et Seloger`,
      `Filtrage des appels téléphoniques avec évaluation approfondie des capacités financières des acquéreurs`,
      `Esquisse 2D améliorée du logement pour une présentation optimale`,
      `Home staging virtuel avec 3 photos, ou visite du logement en 3D pour une surface maximale de 70m2 (au-delà, 10€ par m2 supplémentaire)`,
      `Mise en situation pour la mise en œuvre et la gestion de vos visites`,
      `Adaptations de l'attitude professionnelle lors de la négociation/commercialisation`,
      `Préparation du dossier Notaire`,
      `Mise en relation facilitée avec nos partenaires pour les diagnostics et autres besoins`,
      `Recevez un suivi attentif et une assistance sur mesure tout au long de votre projet, grâce à l'accompagnement dédié de votre conseiller en coaching immobilier, adapté à vos préférences et à votre rythme`,
    ],
    detail: `Nous sommes déterminés à vous accompagner jusqu'à la vente de votre bien. Si la première tentative ne suffit pas, nous réitérerons nos efforts avec la même détermination.`,
    highlighted: true,
    background: "/img/plans/erin-mckenna-QjTUB3TMmLM-unsplash.jpg",
    paymentConditions: ["Un acompte de 15% sera demandé lors de la signature du contrat."]
  }
];
