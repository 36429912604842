import "./CGU.css";

export default function CGU() {
  return (
    <div className="pb-8 pt-14" id="cgu">
      <div className="mx-auto max-w-screen-md px-4 py-8">
        <h1>CGU & CGV France</h1>

        <div id="cgu">
          <h2>1. Conditions d'utilisation France</h2>
          <h3>Propriété intellectuelle</h3>
          <p>
            Le site et sa structure générale, ainsi que les logiciels, ses
            éléments et ses contenus sont la propriété exclusive de{" "}
            <strong>LA BELLE OFFRE</strong>.
          </p>
          <p>
            L'accès et l'utilisation du site labelleoffre.com ne vous confèrent
            aucun droit de propriété sur le site, ses éléments constitutifs ou
            leurs dénominations.
          </p>
          <p>
            La reproduction ou représentation, intégrale ou partielle, des
            pages, des données et de tout autre élément constitutif du Site, par
            quelque procédé ou support que ce soit, est interdite et constitue,
            sans autorisation de l'éditeur, une contrefaçon.
          </p>
          <p>
            Les noms des produits, services ou entités mentionnés sur le Site
            peuvent être des noms commerciaux ou marques déposées appartenant à
            la LA BELLE OFFRE ou des tiers et protégés à ce titre par le droit
            de la propriété intellectuelle.
          </p>
          <p>Toute reproduction non autorisée est passible de contrefaçon.</p>
          <p>
            En aucun cas les visiteurs de ce site ne sont autorisés à publier,
            retransmettre ou reproduire de quelque façon que ce soit tout ou
            partie des informations, illustrations, photographies diffusées, à
            d'autres personnes ou organismes.
          </p>
          <p>
            LA BELLE OFFRE ne peut être tenue responsable de toute utilisation
            détournée ou sortie de son contexte des informations fournies sur
            ses sites, ainsi que de toute inexactitude, erreur ou omission dans
            les informations communiquées. Elle n'est pas non plus responsable
            du transfert des données, de la qualité et de la disponibilité des
            réseaux de télécommunication, ni des interruptions de service.
          </p>
          <p>
            L’ensemble des éléments constituant ce site : texte, graphismes,
            logiciels, photographies, images, vidéos, sons, plans, noms, logos,
            marques, créations et œuvres protégeables diverses, bases de
            données, déposés ou non, et ainsi que le site lui-même relèvent des
            législations françaises sur le droit d’auteur et la protection
            intellectuelle.
          </p>
          <p>
            En particulier, les photos et plans sont la propriété de
            labelleoffre.com et ne peuvent en aucun cas être transmis à des
            tiers. Labelleoffre.com se réserve le droit d'engager des poursuites
            judiciaires en cas de violation.
          </p>
          <p>
            Toute utilisation non expressément autorisée entraîne une violation
            des droits d’auteur et constitue une contrefaçon. Elle peut aussi
            entraîner une violation des droits des personnes et de tous autres
            droits et réglementations en vigueur.
          </p>
          <p>
            Elle peut donc engager la responsabilité civile et/ou pénale de son
            auteur, labelleoffre.com se réserve le droit d’engager des
            poursuites judiciaires à l’encontre de toute personne qui n’aurait
            pas respecté cette interdiction.
          </p>

          <h3>Droit sur les bases de données</h3>
          <p>
            Le site est également une base de données protégée conformément à
            l’article L.341-1 du Code de la Propriété intellectuelle.
            Labelleoffre.com est le producteur de cette base.
          </p>
          <p>
            Les représentations, reproductions, diffusions, extractions ou
            réutilisation, partielles ou totales, des bases de données
            accessibles par l'intermédiaire du site sont expressément interdites
            en vertu des dispositions de la loi n° 98-536 du 1er juillet 1998
            relative à la protection juridique des bases de données.
          </p>
          <p>
            Toute extraction ou utilisation du contenu de la base non
            expressément autorisée, peut engager la responsabilité civile et/ou
            pénale de son auteur.
          </p>
          <p>
            Labelleoffre.com se réserve le droit d’engager des poursuites
            judiciaires à l’encontre de toute personne qui n’aurait pas respecté
            cette interdiction.
          </p>

          <h3>Responsabilités</h3>
          <p>Labelleoffre.com ne saurait être tenu responsable :</p>
          <ul>
            <li>
              des dommages de toute nature, directs ou indirects résultant du
              contenu et/ou de l'accès et/ou de l'utilisation du site (et/ou des
              sites qui lui sont liés), incluant notamment mais non
              limitativement toute perte d'exploitation, financière ou
              commerciale, perte de programmes et/ou de données, toutes
              détériorations ou virus qui pourraient infecter l'équipement
              informatique ou tout autre bien de l'utilisateur du site,
            </li>
            <li>
              des problèmes techniques ou autres rendant l'accès au site (et/ou
              aux sites lui étant liés) difficile, aléatoire, voire impossible,
              et ce quelles qu'en soient l'origine et la provenance,
            </li>
            <li>
              du contenu des informations présentées sur le site, de leur
              interprétation et plus généralement des erreurs ou omissions que
              pourrait contenir le site, bien que Labelleoffre.com s'efforce de
              diffuser des informations précises et de les mettre à jour
              régulièrement.
            </li>
            <li>
              des différences existantes entre la réalité et les créations
              réalisées par Labelleoffre.com (plans, aménagements 3D, photos).
            </li>
          </ul>
          <p>
            En cas d’utilisation anormale ou d’une exploitation illicite du site
            Labelleoffre.com l’utilisateur du site est seul responsable de tous
            dommages causés à tout tiers y compris à Labelleoffre.com et des
            conséquences et actions qui pourraient en découler. L'utilisateur du
            site renonce également à exercer tout recours contre le site
            Labelleoffre.com dans le cas de poursuites diligentées par un tiers
            à son encontre du fait de l'utilisation et/ou de l'exploitation
            illicite du site.
          </p>
          <h3>Le droit de contacter des membres de Labelleoffre.com</h3>
          <p>
            Les membres de labelleoffre.com (propriétaires d'une annonce) ont
            fait appel aux services de LA BELLE OFFRE uniquement dans le but de
            communiquer auprès de particuliers.
          </p>
          <p>
            La prise de contact est donc exclusivement réservée aux particuliers
            à la recherche d'un bien immobilier. Tout démarchage commercial
            entraînera une désactivation immédiate du compte utilisé et de
            toutes ses fonctionnalités.
          </p>
          <h3>Mise en garde</h3>
          <p>
            Les informations contenues dans le site ont été puisées à des
            sources considérées comme fiables et à jour au moment de leur
            publication.
          </p>
          <p>
            Ces informations sont susceptibles d'être modifiées ultérieurement.
          </p>
          <p>
            Malgré tous les soins apportés, le site peut comporter des
            inexactitudes, des défauts de mise à jour ou des erreurs.
          </p>
          <p>
            Les informations d’ordre juridique et fiscal contenues dans ce site
            sont à jour au moment de sa parution et sont susceptibles d’évoluer.
          </p>
          <p>
            Les informations présentées sur le site sont à vocation strictement
            informative et n'emportent aucun engagement juridique ni accord
            contractuel de la part de LA BELLE OFFRE qui se réserve par ailleurs
            la faculté de modifier les caractéristiques des produits présentés.
          </p>
        </div>

        <div id="cgv">
          <h2>2. Conditions générales de vente France</h2>
          <h3>Article 1 - Nos tarifs et règlements</h3>
          <p>
            Les prix de nos prestations sont indiqués en euros incluant la TVA
            au taux en vigueur. Labelleoffre.com se réserve le droit de modifier
            ses prix à tout moment mais seront facturés sur la base des tarifs
            en vigueur au moment de la validation de votre commande.
          </p>
          <p>
            La mise en ligne du bien sur Labelleoffre.com ne peut intervenir
            avant le paiement intégral de la prestation.
          </p>
          <p>Le règlement peut s’effectuer à savoir,</p>
          <div id="formule-coaching-a-lheure">
            <h4>La formule coaching à l’heure :</h4>
            <p>
              Le règlement en totalité doit être effectué lors de la signature
              du contrat;
            </p>
            <ul>
              <li>
                Règlement par carte bancaire (organisme Stripe) sur notre site
                internet ou par virement bancaire
              </li>
              <li>
                Chèque de banque, celui-ci doit être émis par une banque
                domiciliée en France métropolitaine ou à Monaco. . La mise à
                l’encaissement est effectuée dès la réception du chèque;
              </li>
              <li>
                Chèque classique, les prestations seront délivrées une fois que
                le délai légal de vérification de chèque est purgé; rappel des
                dispositions des articles L131-31 à L131-43 du code monétaire et
                financiers;
              </li>
              <li>
                Paiement en espèce jusqu’à 1000,00 euros; Rappel : Article
                D112-3 du code monétaire et financier : Le montant prévu au I de
                l'article L. 112-6 est fixé : Lorsque le débiteur a son domicile
                fiscal sur le territoire de la République française ou agit pour
                les besoins d'une activité professionnelle, à 1 000 euros pour
                les paiements effectués en espèces et à 3 000 euros pour les
                paiements effectués au moyen de monnaie électronique;
              </li>
            </ul>
          </div>
          <div id="formule-premium-proprietaire">
            <h4>Le pack premium propriétaire :</h4>
            <p>
              Un acompte de 30% sera demandé lors de la signature du contrat
              (sauf pour les règlements en carte bancaire);
            </p>
            <p>
              Le solde restant sera à régler une fois la prestation Labelleoffre
              réalisée.
            </p>
            <ul>
              <li>
                Règlement de la totalité du montant par carte bancaire
                (organisme Stripe) sur notre site internet;
              </li>
              <li>
                Chèque de banque, celui-ci doit être émis par une banque
                domiciliée en France métropolitaine ou à Monaco. . La mise à
                l’encaissement est effectuée dès la réception du chèque;
              </li>
              <li>
                Chèque classique, les prestations seront délivrées une fois que
                le délai légal de vérification de chèque est purgé; rappel des
                dispositions des articles L131-31 à L131-43 du code monétaire et
                financiers;
              </li>
              <li>
                Paiement en espèce jusqu’à 1000,00 euros; Rappel : Article
                D112-3 du code monétaire et financier : Le montant prévu au I de
                l'article L. 112-6 est fixé : Lorsque le débiteur a son domicile
                fiscal sur le territoire de la République française ou agit pour
                les besoins d'une activité professionnelle, à 1 000 euros pour
                les paiements effectués en espèces et à 3 000 euros pour les
                paiements effectués au moyen de monnaie électronique;
              </li>
            </ul>
          </div>
          <div id="formule-serenite">
            <h4>La formule Sérénité :</h4>
            <p>
              Un acompte de 15% sera demandé lors de la signature du contrat,
            </p>
            <ul>
              <li>
                Chèque de banque, celui-ci doit être émis par une banque
                domiciliée en France métropolitaine ou à Monaco. . La mise à
                l’encaissement est effectuée dès la réception du chèque;
              </li>
              <li>
                Chèque classique, les prestations seront délivrées une fois que
                le délai légal de vérification de chèque est purgé; rappel des
                dispositions des articles articles L131-31 à L131-43 du code
                monétaire et financiers;
              </li>
              <li>
                Paiement en espèce jusqu’à 1000,00 euros; Rappel : Article
                D112-3 du code monétaire et financier : Le montant prévu au I de
                l'article L. 112-6 est fixé : Lorsque le débiteur a son domicile
                fiscal sur le territoire de la République française ou agit pour
                les besoins d'une activité professionnelle, à 1 000 euros pour
                les paiements effectués en espèces et à 3 000 euros pour les
                paiements effectués au moyen de monnaie électronique;
              </li>
            </ul>
            <p>Le solde restant sera à régler à savoir :</p>
            <ul>
              <li>
                À la vente du bien immobilier, sauf si le bien ne se vend pas
                pour des raisons indépendantes de notre volonté, auquel cas le
                solde sera dû à la fin des 12 mois ou dès 6 publications
                incluses voir CGV.
              </li>
            </ul>
          </div>
          <h3>Article 2 - Commande</h3>
          <h4>Pack premium propriétaire et la formule sérénité</h4>
          <p>
            Le signataire du contrat (propriétaire du bien mise à la vente)
            appelé également utilisateur s’engage à fournir (dans les 15 jours
            suivant la date de signature du contrat) les copies des documents
            nécessaires à la création et à la mise en ligne du bien.{" "}
          </p>
          <p>
            Ces documents sont : l’acte de propriété, le relevé de charges, le
            dernier procès-verbal d’assemblée générale, la taxe d’habitation
            ainsi que la taxe foncière.
          </p>
          <p>
            Nous planifierons un premier rendez-vous afin de voir en détail les
            éléments propres à la stratégie de vente.
          </p>
          <p>
            Par la suite, un rendez-vous sera planifié pour le shooting photos
            et la visite virtuelle sur la même journée.
          </p>
          <h4>La formule coaching à l’heure</h4>
          <p>Un rendez-vous sera convenu entre vous et le coach immobilier. </p>
          <h3>Article 3 - Validation</h3>
          <p>
            Vous déclarez avoir pris connaissance et accepté les présentes
            conditions générales de ventes avant la passation de commande. La
            validation du bon de commande vaut donc acceptation de nos
            conditions.
          </p>
          <h3>Article 4 - Conclusion du contrat</h3>
          <p>
            Dans tous les cas, le contrat de vente n'est réputé conclu qu'au
            moment du débit du compte du consommateur pour les cartes de
            paiement, de la date de réception du virement en cas de virement
            bancaire, et à celui de l'encaissement du chèque pour les paiements
            par chèque.
          </p>
          <p>
            Sauf preuve contraire, les données enregistrées par Labelleoffre.com
            constituent la preuve de l’ensemble des transactions passées avec
            Labelleoffre.com.
          </p>
          <h3>Article 5.1 - Paiement</h3>
          <p>
            Le consommateur déclare expressément disposer de toutes les
            autorisations nécessaires aux fins d'utilisation du mode de paiement
            qu'il a choisi lors de la validation de sa commande.
          </p>
          <p>
            Il s'engage en outre à s'assurer de sa solvabilité avant toute
            commande, faute pour la société de ne pouvoir procéder à la
            délivrance des prestations.
          </p>
          <p>
            La société se ménage en outre la possibilité de suspendre ou
            d'annuler tout contrat en cas de refus de délivrance d'autorisation
            de paiement de la part des organismes financiers interrogés en
            fonction du mode de paiement choisi par le consommateur.
          </p>
          <p>
            De même, elle se ménage la possibilité de refuser de prendre en
            compte un contrat émanant d'un particulier avec lequel un litige
            relatif notamment au paiement d'un précédent contrat subsisterait.
          </p>
          <p>
            Le consommateur est en outre avisé de ce que la société peut
            procéder à l'analyse et/ou à la vérification des éléments fournis
            afin de lutter contre les malversations, et dans ce cadre se réserve
            la possibilité de demander au signataire du contrat ou de la
            commande, tout justificatif qu'elle jugerait utile.
          </p>
          <p>
            Elle se réserve à l'issue de cette procédure la possibilité de
            suspendre ou d'annuler toute commande et mise en ligne, même en cas
            d'autorisation de paiement délivré par les organismes financiers
            concernés, dans les cas où cette analyse et/ou ces vérifications
            donneraient à penser que ladite commande est passée en fraude des
            droits d'un tiers. Elle ne saurait en outre supporter aucune
            responsabilité en cas de malversation ou d'utilisation frauduleuse
            d'un quelconque moyen de paiement qui n'aurait pas été détecté par
            cette procédure d'analyse et/ou de vérification.
          </p>
          <h3>Article 5.2 - Sanctions en cas de retard ou de non-paiement</h3>
          <p>
            En cas de retard ou de non-paiement, des pénalités de retard sont
            exigibles le jour suivant la date de règlement figurant sur la
            facture au taux de 3 fois le taux légal sans qu’un rappel soit
            nécessaire et viendront s’ajouter à la clause pénale prévue
            contractuellement.
          </p>
          <p>
            En outre, un tel retard emportera annulation immédiate et
            irréversible de toutes les réductions de prix qui auraient été
            consenties au client et entraînera la déchéance du terme ainsi qu’à
            la passation du dossier au service contentieux qui procédera au
            recouvrement d’office de l’intégralité de la créance due par tout
            moyen de droit.
          </p>
          <p>
            Tous frais connexes à la procédure sont à la charge exclusive du
            débiteur.
          </p>
          <p>
            Labelleoffre.com se réserve le droit de prononcer la résiliation du
            contrat de plein droit du fait de l’inexécution de l’obligation de
            payer dans les délais requis, sans sommation, ni formalité.
          </p>
          <h3>
            Article 6 - Financement et encaissement par la Caisse d’Épargne
          </h3>
          <p>
            Labelleoffre.com fait appel aux solutions de financement et
            d'encaissement de l’agence Caisse d’Épargne - ALES CAP CEVENNES
            (Gard).
          </p>
          <h3>Article 7 - Livraison</h3>
          <p>
            La mise en ligne de votre bien sur le site Labelleoffre.com est
            effectuée au plus tard un mois après la date indiquée sur le bon de
            commande.
          </p>
          <p>
            Dès la « mise en ligne » du bien un mail d’information est adressé à
            l’utilisateur.
          </p>
          <p>
            Si pour des raisons de forces majeures la mise en ligne ne peut être
            effectuée, dans le délai prévu, Labelleoffre.com ne saurait être
            tenu responsable et s’engage à effectuer le remboursement intégral
            du montant stipulé sur le bon de commande, sans que l’utilisateur ne
            puisse demander des indemnités supplémentaires.
          </p>
          <p>
            Si pour des raisons de la non-disponibilité de l’utilisateur à
            fournir les éléments nécessaires à la mise en ligne de l’annonce du
            bien concerné, Labelleoffre.com ne saurait être tenu responsable du
            dépassement de délai, l’utilisateur ne pourra demander des
            indemnités supplémentaires.
          </p>
          <p>
            Cette clause est applicable seulement dans le cadre de la Formule
            “Sérénité” proposée par Labelleoffre.com, pour la “mise en ligne” de
            l’annonce du bien à vendre.
          </p>
          <h5>Visite virtuelle - Outil numérique MATTERPORT</h5>
          <p>
            <b>Formule premium propriétaire</b> : La visite virtuelle sera
            diffusée en ligne dans un délai de 6 mois à partir de la réalisation
            de cette dernière.
          </p>
          <p>
            Un allongement de durée est possible pour un montant de 10 euros par
            mois.
          </p>
          <p>
            <b>Formule sérénité</b> : La visite virtuelle sera diffusée en ligne
            dans un délai d’un an à partir de la réalisation de cette dernière.
          </p>
          <h3>Article 8 - Nullité - Tolérance</h3>
          <p>
            Dans le cas où une ou plusieurs des stipulations des présentes
            conditions générales seraient tenues pour non valides ou déclarées
            comme telles en application d'une loi, d'un règlement ou à la suite
            d'une décision devenue définitive d'une juridiction compétente, les
            autres stipulations conserveront toute leur force ou leur portée.
          </p>
          <p>
            Le fait pour la société Labelleoffre.com ou le consommateur de ne
            pas se prévaloir d'un manquement à l'une des obligations découlant
            des présentes conditions générales ou de tolérer une situation n'a
            pas pour effet d'accorder à l'autre partie des droits acquis. Une
            telle tolérance ne saurait en aucun cas être interprétée comme une
            renonciation à faire valoir les droits en cause.
          </p>
          <h3>Article 9 - Force majeure - Loi applicable</h3>
          <p>
            Les obligations réciproques résultant des présentes conditions
            générales sont susceptibles d'être suspendues en cas de survenance
            d'un cas fortuit ou de force majeure.
          </p>
          <p>
            Seront notamment considérés comme tels ceux habituellement retenus
            par la jurisprudence des Cours et tribunaux français. Les présentes
            conditions générales sont soumises à la loi française en ce qui
            concerne les règles de fond comme les règles de forme. Tout litige
            devra faire l'objet d'une tentative de règlement amiable.
          </p>
          <h3>Article 10 - Délai de rétractation</h3>
          <p>
            Conformément aux dispositions légales en vigueur, vous disposez de
            14 jours à compter de la signature du contrat pour exercer votre
            droit de rétractation auprès de Labelleoffre.com sans avoir à
            justifier de motifs ni à payer de pénalité selon l’article
            L121-20-12 du code de la consommation.
          </p>
          <p>
            Ce courrier doit être expédié au plus tard le quatorzième jour à
            partir de la commande ou si ce délai expire un samedi, un dimanche
            ou un jour férié ou chômé, le premier jour ouvrable suivant.
          </p>
          <p>
            En cas d’annulation de commande il est possible soit : le courrier
            doit être expédié en courrier en accusé de réception (AR) ou il est
            donné la possibilité d’en informer la société Labelleoffre.com par
            courriel.
          </p>
          <p>
            Dans ce cas Labelleoffre.com en accuse bonne réception par retour de
            mail et par l’envoie d’un courrier de bon enregistrement de
            l’annulation de commande.
          </p>
          <p>
            Dans le cadre d’un contrat conclu à distance par suite d’un
            démarchage téléphonique, l’article L.121-21 alinéa 1 du Code de la
            consommation dispose que dans “Le consommateur dispose d'un délai de
            quatorze jours pour exercer son droit de rétractation d'un contrat
            conclu à distance, à la suite d'un démarchage téléphonique ou hors
            établissement, sans avoir à motiver sa décision ni à supporter
            d'autres coûts que ceux prévus aux articles L. 121-21-3 à L.
            121-21-5.
          </p>
          <p>
            Toute clause par laquelle le consommateur abandonne son droit de
            rétractation est nulle. “
          </p>
          <p>
            Adresse de contact : contact@labelleoffre.com, Par courrier : LA
            BELLE OFFRE, 10, rue des Grillons, 30132 CAISSARGUES.
          </p>
          <h3>Article 11 - Remboursement</h3>
          <p>
            Les remboursements dans les hypothèses des articles 5, 7, 8 et 9
            seront effectués dans les meilleurs délais et au plus tard dans les
            30 jours suivant la date à laquelle le droit a été exercé. Le
            remboursement s’effectuera sur proposition de Labelleoffre.com par
            virement bancaire adressé au client ayant passé la commande et à
            l’adresse de facturation.
          </p>
          <h3>Article 12 - Droit applicable-litiges</h3>
          <ul>
            <li>
              Les présentes conditions légales sont soumises à la loi française.
            </li>
            <li>
              La langue des présentes mentions légales sont en langue française.
            </li>
            <li>
              En cas de litige, les tribunaux français seront seuls compétents.
            </li>
          </ul>
          <h3>Article 13 - Service clientèle</h3>
          <p>
            Pour toutes informations ou questions, notre service clientèle est à
            votre disposition : du lundi au vendredi de 9h00 à 12h00 et de 14h00
            à 18h00, et le samedi de 9h00 à 13h00 au 04 65 84 85 56.
          </p>
          <h3>Article 14 - Suivi commande</h3>
          <p>Pour le suivi de commande veuillez nous contacter :</p>
          <ul>
            <li>par e-mail : contact@labelleoffre.com</li>
            <li>par téléphone : 04 65 84 85 56</li>
          </ul>
          <h3>
            Article 15 - Informations nominatives et politique de
            confidentialité
          </h3>
          <p>
            Responsable de traitement et délégué à la protection des données
            personnelles Les données personnelles sont collectées sur le site
            Labelleoffre.com, par LA BELLE OFFRE - 10 Rue des grillons – 30 132
            CAISSARGUES, N° SIRET : 978 185 098 00016 Le responsable de
            traitement de LA BELLE OFFRE est M. Steven QUENEA, Président de LA
            BELLE OFFRE. Le délégué à la protection des données est M. Steven
            QUENEA le Président de la BELLE OFFRE. Il est chargé d'assurer la
            conformité des activités de l'entreprise avec le cadre légal du RGPD
            et de coopérer avec l'autorité de contrôle. Pour toute demande de
            contact, veuillez utiliser le formulaire de contact du site
            Internet, ou adresser un courrier postal au siège de LA BELLE OFFRE,
            10 Rue des grillons – 30 132 CAISSARGUES
          </p>
          <h3>Données collectées</h3>
          <p>
            Conformément à la loi "Informatique et Libertés" n° 78-17 du 6
            janvier 1978, Labelleoffre.com procède aux formalités déclaratives
            de ses traitements de données.
          </p>
          <p>
            Les informations recueillies sur le Site Labelleoffre.com font
            l'objet d'un traitement informatique destiné à la gestion des
            clients particuliers ou professionnels et candidats au recrutement,
            conformément aux recommandations de la CNIL.
          </p>
          <p>
            Les données seront conservées pendant une durée qui n'excède pas la
            durée nécessaire à cette finalité. Le destinataire des données est
            LA BELLE OFFRE.
          </p>
          <p>
            Labelleoffre.com pourra divulguer les données personnelles vous
            concernant si cela est requis par la loi et la réglementation en
            vigueur ou si elle pense de bonne foi que leur divulgation peut être
            nécessaire pour (a) se conformer aux règles de procédure
            administrative ou judiciaire, (b) répondre à une plainte ou une
            réclamation justifiée portant sur la violation manifeste des droits
            d'un tiers et (c) protéger les droits, les biens ou la sécurité
            personnelle d'un utilisateur, de LA BELLE OFFRE, ou un de ses
            agents, préposés, prestataires, employés ou autre.
          </p>
          <p>
            Nous vous rappelons que vous bénéficiez d'un droit d'accès, de
            rectification et de modification des données personnelles vous
            concernant, sous réserve de justifier de votre identité.
          </p>
          <p>
            Si vous souhaitez exercer ce droit, veuillez nous adresser votre
            demande par courrier postal adressé à LA BELLE OFFRE, 10 Rue des
            grillons – 30 132 CAISSARGUES.
          </p>
          <p>
            En outre, vous êtes informé que Labelleoffre.com met en œuvre un
            traitement de données ayant pour finalité la lutte contre le
            blanchiment des capitaux et le financement du terrorisme et que le
            régime de droit d’accès aux données recueillies par l’application
            des articles L561-5 à L561-23 du code monétaire et financier
            s’exerce auprès de la Commission nationale de l’informatique et des
            libertés (articles L561-45 du code monétaire et financier). La
            visite du Site occasionne, lorsque cela est possible, l'emploi d'un
            cookie de session à des fins d'analyse statistique et de mesure
            d'audience du Site. Vous pouvez toutefois désactiver ce cookie dans
            les réglages de votre navigateur internet.
          </p>
          <h3>Confidentialité des données personnelles</h3>
          <p>
            Les données personnelles collectées sont uniquement destinées à LA
            BELLE OFFRE et ses partenaires et seront uniquement exploitées dans
            le cadre de la soumission effective d'un formulaire du site.
          </p>
          <p>
            Ces données ne seront jamais commercialisées à des tiers, louées ou
            transmises, en dehors des cas suivants :
          </p>
          <ul>
            <li>
              Information à destination de nos partenaires dans le cadre de
              l’exécution de notre mission.
            </li>
            <li>
              Insultes et/ou propos portant atteinte à l'ordre public ou aux
              bonnes mœurs.
            </li>
            <li>
              Poursuites pénales engagées contre vous : les données pourront
              être transmises à la demande de la justice.
            </li>
          </ul>
          <h3>Sécurité et stockage des données personnelles</h3>
          <p>
            Les données sont stockées sur les serveurs de l'hébergeur. Ces
            serveurs sont situés en France métropolitaine, au sein de l'Union
            Européenne. Il n'y aura donc aucun transfert de données à caractère
            personnel à destination d'un état non-membre de la Communauté
            Européenne.
          </p>
          <h3>
            Droit d'accès, de rectification ou suppression des données
            personnelles
          </h3>
          <p>
            Conformément à la loi Informatique et Libertés du 6 janvier 1978
            modifiée, ainsi qu'au Règlement Européen Général sur la Protection
            des Données 2016/679 (RGPD), vous disposez des droits d’accès, de
            rectification et de suppression des données personnelles vous
            concernant, que vous pouvez exercer en utilisant le formulaire de
            contact du site ou en nous faisant parvenir un courrier postal à
            l'adresse : LA BELLE OFFRE, 10 Rue des grillons – 30 132
            CAISSARGUES.
          </p>
          <p>
            Conformément à la réglementation en vigueur, votre courrier doit
            être signé et accompagné de la photocopie d’un titre d’identité
            portant votre signature et préciser l’adresse à laquelle doit vous
            parvenir la réponse. Une réponse vous sera alors adressée dans un
            délai de 2 mois suivant la réception de la demande.
          </p>
          <p>
            Le site Labelleoffre.com est édité par la société LA BELLE OFFRE
            Forme juridique : SASU Adresse du siège social : 10 Rue des grillons
            – 30 132 CAISSARGUES Capital social : 1 000,00 € N° SIRET : 978 185
            098 00016 RCS : Inscrit au greffe de NIMES , le 04/08/2023
          </p>
          <p>
            Le site Labelleoffre.com est un établissement d'assistance et de
            conseils aux particuliers, de création et gestion de contenu, ainsi
            que de communication et multimédia. Conformément à la Loi
            Informatique et Libertés (Loi n° 78-17 du 6 janvier 1978) vous
            disposez d’un droit d’accès, d’opposition et de modification des
            données qui vous concernent en vous adressant directement à la LA
            BELLE OFFRE : Adresse de contact : contact@labelleoffre.com, Par
            courrier : LA BELLE OFFRE, 10, rue des Grillons, 30132 CAISSARGUES.
          </p>
          <h3>Article 16 - Diffusion des annonces</h3>
          <p>
            Les biens présentés sur Labelleoffre.com sont diffusés sur les sites
            listés ci-dessous et ce pour une durée variable selon l’offre
            choisie par l’utilisateur.
          </p>
          <p>
            Cette liste n'est pas exhaustive, elle est amenée à évoluer
            régulièrement en fonction des nouveaux partenariats signés. Certains
            sites de diffusion ne concernent que des biens situés dans des zones
            géographiques, d'autres sites ne diffusent que certaines catégories
            de biens, ce qui ne peut pas garantir la diffusion sur l'ensemble
            des sites cités.
          </p>
        </div>
      </div>
    </div>
  );
}
