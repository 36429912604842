import { useKeenSlider } from "keen-slider/react";
import { Rating } from "./Rating";
import { TestimonialsData } from "./TestimonialsData";
import { useInView, animated } from "@react-spring/web";
import { twMerge } from "tailwind-merge";
import "keen-slider/keen-slider.min.css";

export default function Testimonials() {
  const [animatedDivRef, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
      config: {
        tension: 300,
      },
    }),
    { once: true, rootMargin: "-10% 0%" },
  );

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        origin: "center",
        perView: 1.25,
        spacing: 16,
      },
      breakpoints: {
        "(min-width: 1024px)": {
          slides: {
            origin: "auto",
            perView: 2.5,
            spacing: 32,
          },
        },
      },
    },
    [],
  );

  function next() {
    instanceRef.current?.next();
  }
  function previous() {
    instanceRef.current?.prev();
  }

  function NavButton({ text, isPrevious, handler }) {
    return (
      <>
        <button
          aria-label={text}
          id="keen-slider-previous"
          className="size-12 rounded-full border border-labelleoffre-secondary bg-white p-3 text-labelleoffre-secondary transition hover:bg-labelleoffre-secondary hover:text-white"
          onClick={handler}
        >
          <i
            className={twMerge(
              "fa-solid fa-chevron-right",
              isPrevious ? "rotate-180" : "rotate-0",
            )}
          />
        </button>
      </>
    );
  }

  function TestimonialItem({ testimonial }) {
    return (
      <blockquote className=" flex h-full max-w-md flex-col justify-between rounded-md bg-white p-6 shadow-sm sm:p-8 lg:p-12">
        <div className="w-full">
          <div className="flex gap-0.5 text-yellow-400">
            <Rating rating={testimonial.rating} />
          </div>
          <div className="mt-4">
            <p className="text-2xl font-bold text-labelleoffre-tertiary sm:text-3xl">
              {testimonial.title}
            </p>
            <p className="mt-4 leading-relaxed text-black/80">
              {testimonial.description}
            </p>
          </div>
        </div>

        <footer className="mt-4 text-sm font-medium text-labelleoffre-secondary/70 sm:mt-6">
          &mdash; {testimonial.author}
        </footer>
      </blockquote>
    );
  }

  return (
    <section className="bg-white">
      <animated.div
        className="mx-auto max-w-screen-xl py-12 sm:px-6 md:px-4 lg:px-8 lg:py-16"
        ref={animatedDivRef}
        style={springs}
      >
        <section className="bg-labelleoffre-tertiary-light md:rounded-3xl">
          <div className="mx-auto max-w-[1340px] px-4 py-12 sm:px-6 lg:me-0 lg:py-16 lg:pe-0 lg:ps-8 xl:py-24">
            <div className="max-w-7xl items-end justify-between sm:flex sm:pe-6 lg:pe-8">
              <h2 className="max-w-xl text-4xl font-bold tracking-tight text-labelleoffre-secondary sm:text-5xl">
                Avis vérifiés de nos clients
              </h2>

              <div className="mt-8 hidden gap-4 md:flex lg:mt-0">
                <NavButton
                  text="Avis précédent"
                  isPrevious={true}
                  handler={previous}
                />
                <NavButton
                  text="Avis suivant"
                  isPrevious={false}
                  handler={next}
                />
              </div>
            </div>

            <div className="-mx-6 mt-8 lg:col-span-2 lg:mx-0">
              <div
                className="keen-slider md:[mask-image:_linear-gradient(to_right,transparent_0,_black_0,_black_calc(100%-200px),transparent_100%)]"
                ref={sliderRef}
              >
                {TestimonialsData.map((testimonial, i) => (
                  <div className="keen-slider__slide" key={`testimonial-${i}`}>
                    <TestimonialItem testimonial={testimonial} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </animated.div>
    </section>
  );
}
