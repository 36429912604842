import { routes } from "./routes";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

export default function Nav() {
  return (
    <>
      <DesktopMenu routes={routes} />
      <MobileMenu routes={routes} />
    </>
  );
}
