import FaqComponent from "./faq/FaqComponent";
import Hero from "./hero/Hero";
import Plans from "./plans/Plans";
import Testimonials from "./testimonials/Testimonials";
import CallToAction from "./cta/CallToAction";
import Partners from "./partners/Partners";
import Savings from "./savings/Savings";
import QuizCTA from "./quiz-cta/QuizCTA";
import { ParallaxProvider } from "react-scroll-parallax";

export default function Home() {
  return (
    <ParallaxProvider>
      <div className="pt-14">
        <Hero showButtons={true} showIndices={true} />
        <CallToAction />
        <Plans />
        <Savings />
        <QuizCTA />
        <Partners />
        <Testimonials />
        <FaqComponent />
      </div>
    </ParallaxProvider>
  );
}
