import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import "./TableOfContents.css";

export default function TableOfContents({
  label = "Table des matières",
  className = "",
  isOpen = false,
}) {
  const useIntersectionObserver = () => {
    const headingElementsRef = useRef({});

    useEffect(() => {
      const callback = (headings) => {
        headingElementsRef.current = headings.reduce((map, headingElement) => {
          map[headingElement.target.id] = headingElement;
          return map;
        }, headingElementsRef.current);

        const visibleHeadings = [];
        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting) {
            visibleHeadings.push(headingElement);
          }
        });
        const getIndexFromId = (id) =>
          headingElements.findIndex((heading) => heading.id === id);

        if (visibleHeadings.length === 1) {
          setActiveId(visibleHeadings[0].target.id);
        } else if (visibleHeadings.length > 1) {
          const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id),
          );
          setActiveId(sortedVisibleHeadings[0].target.id);
        }
      };
      const observer = new IntersectionObserver(callback, {
        rootMargin: "-110px 0px -40% 0px",
      });

      const headingElements = Array.from(document.querySelectorAll("h2, h3"));

      headingElements.forEach((element) => observer.observe(element));

      return () => observer.disconnect();
    }, []);
  };
  const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState([]);
    useEffect(() => {
      const headingElements = Array.from(
        document.querySelectorAll("h2, h3"),
      ).filter((x) => x.id);

      const newNestedHeadings = getNestedHeadings(headingElements);
      setNestedHeadings(newNestedHeadings);
    }, []);
    return { nestedHeadings };
  };
  const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];

    headingElements.forEach((heading, index) => {
      const { innerText: title, id } = heading;

      if (heading.nodeName === "H2") {
        nestedHeadings.push({ id, title, items: [] });
      } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title,
        });
      }
    });

    return nestedHeadings;
  };
  const Heading = ({ heading, activeId }) => {
    const scrollToElement = (e) => {
      e.preventDefault();

      const element = document.querySelector(`#${heading.id}`);
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: "smooth" });

      // en vue mobile, on pourrait fermer le sommaire
    };
    return (
      <li
        key={heading.id}
        className={twMerge(
          "text-ellipsis p-2 text-base transition-colors",
          heading.id === activeId ? "toc-active" : "",
        )}
      >
        <a
          className="overflow-hidden text-ellipsis font-light"
          href={`#${heading.id}`}
          onClick={scrollToElement}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <Heading heading={child} activeId={activeId} />
            ))}
          </ul>
        )}
      </li>
    );
  };
  const Headings = ({ headings, activeId }) => (
    <ul className="divide-y-4 divide-transparent">
      {headings.map((heading) => (
        <Heading
          heading={heading}
          activeId={activeId}
          key={`heading-${heading.id}`}
        />
      ))}
    </ul>
  );

  const [activeId, setActiveId] = useState();
  const [open, setOpen] = useState(isOpen);
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav
      aria-label={label}
      className={twMerge("z-10", open ? "sticky" : "", className)}
    >
      <div className="flex">
        <h1
          className="cursor-pointer select-none p-2 text-xl"
          onClick={() => setOpen(!open)}
        >
          <i
            className={twMerge(
              "fa-solid fa-chevron-down transition-transform",
              open === true ? "rotate-180" : "rotate-0",
            )}
          />
          &nbsp;
          {label}
        </h1>
      </div>
      {open && <Headings headings={nestedHeadings} activeId={activeId} />}
    </nav>
  );
}
