import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function Footer() {
  const mainLinks = [
    {
      name: "Accueil",
      href: "/",
    },
    {
      name: "Formule Sur Mesure",
      href: "/formule/sur-mesure",
    },
    {
      name: "Formule Sérénité",
      href: "/formule/serenite",
    },
    {
      name: "Pack Premium",
      href: "/formule/premium",
    },
  ];

  const footerPartners = [
    // à décommenter lorsque Steven me le dira
    // {
    //   id: "fndci",
    //   name: "FNDCI",
    //   img: "/img/footer/logo-fndci.jpg",
    //   url: "https://federation-des-coachs-immobiliers.fr/",
    // },
    {
      id: "jesuiscoach.fr",
      name: "jesuiscoach.fr",
      img: "/img/footer/logo-jesuiscoach.png",
      url: "https://www.jesuiscoach.fr/",
    },
  ];

  const footerRoutes = [
    {
      name: "Mentions Légales",
      href: "/mentions-legales",
    },
    {
      name: "CGU",
      href: "/cgu",
    },
    {
      name: "Nous contacter",
      href: "/contact",
    },
  ];

  function LinkItems({ links, className }) {
    return (
      <ul className={twMerge("flex gap-12 ", className)}>
        {links.map((page, i) => (
          <li
            className="cursor-pointer transition-colors duration-500"
            key={`footer-link-${i}`}
          >
            <Link className="hover:text-labelleoffre-tertiary" to={page.href}>
              {page.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
  function SocialLinks() {
    return (
      <>
        <ul className="flex gap-2 md:mt-0">
          <li key="linkedin">
            <a
              href="https://www.linkedin.com/in/steven-quenea-92a280110/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin text-2xl text-labelleoffre-secondary transition-colors duration-500 hover:text-labelleoffre-tertiary"></i>
            </a>
          </li>
          <li key="facebook">
            <a
              href="https://www.facebook.com/profile.php?id=100095375971038"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square text-2xl text-labelleoffre-secondary transition-colors duration-500 hover:text-labelleoffre-tertiary"></i>
            </a>
          </li>
        </ul>
      </>
    );
  }

  return (
    <footer className="bg-labelleoffre-tertiary-light py-8 text-labelleoffre-secondary">
      <div className="container flex flex-col items-center gap-2 md:flex-row">
        <div className="mx-auto block items-center justify-center gap-12 md:flex md:flex-1 md:flex-wrap md:justify-start">
          <Link to="/">
            <img
              src="/img/logo-col.png"
              className="h-24"
              alt="logo La Belle Offre"
            />
            <p className="sr-only">La Belle Offre</p>
          </Link>
          <div className="grid grid-cols-2 items-center justify-start gap-2 pt-4 md:pt-0">
            <LinkItems
              className="grid grid-cols-1 gap-0 border-r border-labelleoffre-secondary pr-2 text-base font-light"
              links={mainLinks}
            />
            <LinkItems
              className="grid grid-cols-1 gap-0 text-base font-light"
              links={footerRoutes}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          {footerPartners.map((partner) => (
            <>
              <a className="" href={partner.url} key={partner.id}>
                <img src={partner.img} className="h-12" alt={partner.name} />
                <p className="sr-only">{partner.name}</p>
              </a>
            </>
          ))}
        </div>
        <SocialLinks />
      </div>
    </footer>
  );
}
