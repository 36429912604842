export default function Partners() {
  const partners = [
    {
      name: "ABFA Formation",
      img: "/img/partners/abfa-formation.jpg",
    },
    {
      name: "Amorin Patrimoine",
      img: "/img/partners/amorin-patrimoine.jpg",
    },
    {
      name: "VacanSign",
      img: "/img/partners/vacansign.png",
      link: "https://www.vacansign.fr",
    },
    {
      name: "Sarah Maquaire Déco",
      img: "/img/partners/sarah-maquaire-logo.png",
      link: "https://www.sarahmaquairedeco.fr",
    },
    {
      name: "Déménagement Gaby",
      img: "/img/partners/demenagements-gaby.jpg",
      link: "https://www.demenagements-gaby.fr/",
    },
    {
      name: "JP Plomberie & Climatisation",
      img: "/img/partners/JP-Plomberie-Climatisation.jpg",
      link: "https://www.jp-plomberie-climatisation.fr/",
    },
    {
      name: "SARL Jeanney Bâtiment",
      img: "/img/partners/sarl-jeanney-batiment.jpg",
      link: "https://www.facebook.com/sarljeanneybatiment/",
    },
  ];

  function Partner({ name, img, link, showText }) {
    return (
      <li>
        {link && (
          <>
            <a
              className="pt-2 text-center text-labelleoffre-tertiary"
              href={link}
              target="_BLANK"
              rel="noreferrer"
            >
              <img
                src={img}
                alt={name}
                className="h-44 w-44 cursor-pointer object-scale-down transition-transform duration-500 hover:scale-110"
              />
            </a>

            {showText === true && (
              <p className="pt-2 text-center text-sm text-labelleoffre-tertiary">
                <a
                  className="pt-2 text-center text-labelleoffre-tertiary"
                  href={link}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  {name}
                </a>
              </p>
            )}
          </>
        )}
        {!link && (
          <>
            <img
              src={img}
              alt={name}
              className="h-44 w-44 object-scale-down transition-transform duration-500 hover:scale-110"
            />
            {showText === true && (
              <p className="pt-2 text-center text-sm text-labelleoffre-tertiary/70">
                {name}
              </p>
            )}
          </>
        )}
      </li>
    );
  }
  return (
    <div className="mx-auto max-w-screen-xl pb-8 pt-10">
      <div className="overflow-hidden">
        <h1 className="pb-4 text-center tracking-tighter text-labelleoffre-secondary">
          Nos partenaires
        </h1>
        <div className="group inline-flex w-full flex-nowrap [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
          <ul className="flex animate-infinite-scroll items-center justify-center gap-0 running group-hover:paused md:justify-start [&_img]:max-w-none [&_li]:mx-8">
            {partners.map((partner, i) => (
              <Partner
                name={partner.name}
                img={partner.img}
                link={partner.link}
                key={`partner-${i}`}
                showText={false}
              />
            ))}
          </ul>
          <ul
            aria-hidden="true"
            className="flex animate-infinite-scroll items-center justify-center running group-hover:paused md:justify-start [&_img]:max-w-none  [&_li]:mx-8"
          >
            {partners.map((partner, i) => (
              <Partner
                name={partner.name}
                img={partner.img}
                link={partner.link}
                key={`partner-${i}`}
                showText={false}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
