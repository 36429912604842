export const FaqData = [
  {
    title: `Qu'est-ce que La Belle Offre ?`,
    answer: (
      <>
        <p>
          La Belle Offre est une société de coaching en immobilier qui se
          spécialise dans l'accompagnement des propriétaires pour vendre leur
          bien immobilier avec succès.
        </p>
        <p>
          Nous offrons des conseils personnalisés et des solutions sur mesure
          afin de vous simplifier la tâche et vous décharger des tâches
          fastidieuses. Notre objectif est de rendre le processus de vente plus
          facile et plus efficace pour vous.
        </p>
      </>
    ),
    isOpen: false,
  },
  {
    title: `Quels sont les avantages du coaching immobilier La Belle Offre ?`,
    answer: (
      <>
        <p>
          Avec La Belle Offre, bénéficiez d'une expertise personnalisée, d'un
          accompagnement complet, d'un réseau de partenaires qualifiés,
          d'économies garanties, d'une flexibilité totale, d'un accompagnement
          pas à pas, et d'une visibilité optimale.
        </p>
        <p>
          Notre service implique une consultation essentielle et approfondie de
          vos objectifs de vente, de vos préoccupations et de vos attentes. Sur
          la base de ces informations, nous élaborerons un plan sur mesure pour
          maximiser le potentiel de votre propriété sur le marché et vous
          conseillerons sur la formule qui vous correspond le mieux à votre
          situation de vente.
        </p>
      </>
    ),
    isOpen: false,
  },
  {
    title:
      "Quelle est la différence entre un agent immobilier et un coach immobilier ?",
    answer: (
      <>
        <p>
          L'agent immobilier met en relation vendeurs et acheteurs, gagnant une
          commission basée sur le prix de vente, ce qui l'engage personnellement
          dans la vente.
        </p>
        <p>
          En revanche, le coach immobilier offre des conseils neutres pour les
          ventes entre particuliers sans intervenir dans la transaction,
          recevant une rémunération forfaitaire. Le coach privilégie le soutien
          personnalisé sans implication financière directe, assurant une
          approche neutre axée sur les besoins du propriétaire.
        </p>
      </>
    ),
    isOpen: false,
  },
  {
    title: `Pourquoi devrais-je faire appel à un coach immobilier plutôt que de vendre par l'intermédiaire d'une agence ou entre particuliers ?`,
    answer: (
      <>
        <p>
          Opter pour les services d'un coach immobilier offre une approche
          visuelle et financière optimale.
        </p>
        <p>
          La vente via une agence immobilière peut entraîner des coûts élevés,
          décourageant ainsi certains propriétaires. Ainsi, la vente entre
          particuliers peut sembler attrayante pour éviter ces frais, mais la
          complexité de la législation immobilière peut entraîner des
          conséquences financières et des retards dans la transaction. Seulement
          20% des particuliers parviennent à vendre leur bien sans agence,
          tandis que 80% se heurtent à diverses difficultés.
        </p>
        <p>
          Le rôle essentiel du coach immobilier est de guider visuellement le
          propriétaire tout au long du processus de vente. En fournissant des
          informations cruciales et des conseils dans les domaines commercial,
          administratif et règlementaire, le coach immobilier offre une vision
          claire pour éviter les erreurs courantes associées à la vente entre
          particuliers.
        </p>
      </>
    ),
    isOpen: false,
  },
  {
    title: `Quels types de biens ne sont pas couverts par vos services de coaching ?`,
    answer: (
      <>
        <p>
          Nos services ne couvrent pas les cas particuliers tels que les ventes
          viagères, les VEFA (Vente en l'État Futur d'Achèvement), les locaux
          commerciaux, et les terrains.
        </p>
        <p>Notre expertise se concentre sur les biens résidentiels.</p>
      </>
    ),
    isOpen: false,
  },
  {
    title: `Comment puis-je bénéficier du parrainage et des chèques-cadeaux ?`,
    answer: (
      <>
        <p>
          En recommandant La Belle Offre à vos proches qui souhaitent vendre
          leur bien immobilier, vous pouvez participer à notre programme de
          parrainage.
        </p>
        <p>
          Chaque recommandation réussie vous donne droit à des chèques-cadeaux
          d'une valeur allant de 50 à 200 €, en fonction de la formule de
          service choisie, à l'exception du coaching individuel à l'heure.
        </p>
        <p>A retrouver dans la rubrique “Nos formules !”</p>
        <p>Formule 2 : Pack Premium Propriétaire</p>
        <p>Formule 3 : Formule Sérénité</p>
      </>
    ),
    isOpen: false,
  },

  // EXTRA QUESTIONS
  {
    title: `Quels avantages offre votre coaching par rapport à d'autres services immobiliers?`,
    answer: (
      <>
        <p>
          Notre coaching se distingue par sa focalisation sur les biens
          résidentiels et son expertise approfondie dans ce domaine. Nous
          offrons des conseils personnalisés et des stratégies adaptées pour
          optimiser la vente de votre propriété.
        </p>
      </>
    ),
    isOpen: false,
    isExtra: true,
  },
  {
    title: `Est-il possible d'accélérer la vente de mon bien immobilier avec La Belle Offre ?`,
    answer: (
      <>
        <p>
          Nous nous efforçons de maximiser vos chances de vendre votre propriété
          rapidement et dans des conditions optimales. Néanmoins, plusieurs
          variables, dont le prix, l'emplacement et la demande du marché,
          influencent la durée nécessaire pour conclure la vente. Nous
          travaillons en étroite collaboration avec vous pour atteindre vos
          objectifs de vente dans les délais qui vous conviennent le mieux.
        </p>
      </>
    ),
    isOpen: false,
    isExtra: true,
  },
  {
    title: `Quels types de biens proposez-vous dans votre coaching immobilier?`,
    answer: (
      <>
        <p>
          Notre coaching immobilier La Belle Offre se spécialise dans la vente
          de biens à usage d'habitation. Cela englobe une variété de propriétés
          résidentielles telles que des maisons, des appartements, des villas et
          des maisons de ville.
        </p>
      </>
    ),
    isOpen: false,
    isExtra: true,
  },
  {
    title: `Comment opère le service de coaching immobilier La Belle Offre?`,
    answer: (
      <>
        <p>
          Le coaching immobilier de La Belle Offre se présente comme un service
          de conseils, soulignant son engagement à vous fournir les moyens
          nécessaires pour réaliser la vente de votre bien immobilier.
        </p>
        <p>
          Il est important de noter que le coaching immobilier garantit une
          obligation de moyens plutôt que de résultats. Bien que les ressources
          mises à votre disposition facilitent la gestion de votre vente, vous
          demeurez l'acteur principal de sa réussite.
        </p>
        <p>
          De plus, étant donné les imprévus inhérents au processus de vente
          immobilière, La Belle Offre ne peut garantir la conclusion de la
          transaction.
        </p>
      </>
    ),
    isOpen: false,
    isExtra: true,
  },
];
