import { useState } from "react";
import { useKeenSlider } from "keen-slider/react"; // import from 'keen-slider/react.es' to get an ES module
import { twMerge } from "tailwind-merge";
import { items } from "./items";
import HeroItem from "./HeroItem";

import "keen-slider/keen-slider.min.css";
import "./Hero.css";

export default function Hero({ showButtons, showIndices }) {
  const autoSlideInterval = 6_000;
  const [opacities, setOpacities] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      vertical: true,
      loop: true,
      detailsChanged(s) {
        const new_opacities = s.track.details.slides.map(
          (slide) => slide.portion,
        );
        setOpacities(new_opacities);
      },
      created() {
        setLoaded(true);
      },
      drag: false,
    },
    [
      (slider) => {
        let timeout;
        // let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          // if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, autoSlideInterval);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            // mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            // mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ],
  );

  const next = () => {
    instanceRef.current?.next();
  };
  const previous = () => {
    instanceRef.current?.prev();
  };

  function NavigationButton({ isPrevious, handler }) {
    return (
      <button
        onClick={handler}
        className="h-8 w-8 rounded-full bg-white/60 hover:bg-white"
      >
        <i
          className={twMerge(
            "fa-solid p-2 text-labelleoffre-tertiary",
            isPrevious === true ? "fa-arrow-up" : "fa-arrow-down",
          )}
        />
      </button>
    );
  }

  return (
    <section className="mx-auto max-w-full justify-center bg-labelleoffre-primary py-0">
      <div
        ref={sliderRef}
        className="keen-slider h-[550px] overflow-hidden md:h-[600px] lg:h-[700px] xl:h-[800px]"
      >
        {items.map((item, i) => (
          <div
            className="keen-slider__slide"
            key={`slider-item-${i}`}
            style={{ opacity: opacities[i] }}
          >
            <HeroItem
              key={`hero-item-${i}`}
              title={item.title}
              image={item.image}
              content={item.content}
              cta={item.cta}
            />
          </div>
        ))}
      </div>

      {/* nav section */}
      {showButtons === true && loaded && instanceRef.current && (
        <div className="absolute right-2 top-16 h-[550px] w-14 py-0 md:h-[600px] lg:h-[700px] xl:h-[800px]">
          <div className="h-full content-center justify-end align-middle">
            <div className="grid grid-rows-1 justify-end gap-1">
              <NavigationButton isPrevious={true} handler={previous} />
              <NavigationButton handler={next} />
            </div>
          </div>
        </div>
      )}

      {/* index section */}
      {showIndices === true && loaded && instanceRef.current && (
        <div className="absolute top-[550px] w-full py-2 md:top-[600px] lg:top-[700px] xl:top-[800px]">
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
}
