import { ParallaxBanner } from "react-scroll-parallax";
import HeroButton from "./HeroButton";

export default function HeroItem({ title, content, image, cta }) {
  function Gradient() {
    return (
      <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white sm:to-white/50" />
    );
  }

  return (
    <ParallaxBanner
      className="relative h-full bg-cover bg-center bg-no-repeat"
      // style={{ background: `url(${image.url})` }}
      layers={[
        {
          image: image.url,
          speed: -10,
        },
      ]}
    >
      <Gradient />
      <div className="relative mx-auto max-w-screen-xl px-4 py-6 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div className="mx-auto max-w-3xl">
          <h1 className="text-center text-3xl font-semibold tracking-tight text-labelleoffre-secondary md:text-7xl md:tracking-tighter">
            {title}
          </h1>
          <p className="w-fit pt-2 text-left text-base font-light md:text-center md:text-xl/snug md:tracking-normal">
            {content}
          </p>
          <div className="mx-auto mt-8 flex w-fit flex-wrap gap-4 text-center">
            <HeroButton text={cta.text} link={cta.url} />
          </div>
        </div>
      </div>
    </ParallaxBanner>
  );
}
