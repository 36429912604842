import { useState } from "react";
import Logo from "./Logo";
import { useSpring, animated } from "@react-spring/web";
import { twMerge } from "tailwind-merge";

export default function MobileMenu({ routes }) {
  function MobileButtonToggle({ handler }) {
    return (
      <div className="flex items-center md:hidden">
        <button onClick={handler} className="text-black/90">
          <i className="fas fa-bars cursor-pointer text-2xl"></i>
        </button>
      </div>
    );
  }
  const MobileLink = ({ name, href, subitems, className }) => {
    const [subMenuOpened, toggleSubMenu] = useState(false);
    const springs = useSpring({
      from: { opacity: 0 },
      to: { opacity: subMenuOpened ? 1 : 0 },
    });

    const showSubmenu = subMenuOpened && subitems;
    return (
      <>
        <div
          onClick={() => toggleSubMenu(!subMenuOpened)}
          className={twMerge("py-4", className)}
        >
          <a
            href={href}
            className="text-xl font-light uppercase text-labelleoffre-secondary"
            onClick={() => {
              if (subitems) {
                return;
              }
              toggleMobileMenu();
            }}
          >
            {name}

            {subitems && (
              <>
                &nbsp;
                <i
                  className={twMerge(
                    "fa-solid",
                    showSubmenu ? "fa-chevron-up" : "fa-chevron-down",
                  )}
                />
              </>
            )}
          </a>
        </div>

        {showSubmenu && (
          <animated.div style={springs} className="block">
            {subitems.map((route) => (
              <MobileLink
                href={route.url}
                key={route.id}
                name={route.name}
                className="pl-2"
              />
            ))}
          </animated.div>
        )}
      </>
    );
  };

  const [open, toggle] = useState(false);
  const mobileMenuSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
  });

  function toggleMobileMenu() {
    toggle(!open);
  }

  return (
    <>
      <nav className="fixed z-10 block w-full bg-labelleoffre-white md:hidden">
        <div className="mx-auto px-2 shadow-sm">
          <div className="flex h-16 justify-between px-2">
            <Logo />
            <MobileButtonToggle handler={toggleMobileMenu} />
          </div>
          {open && (
            <animated.div
              className="block divide-y divide-transparent pb-4 pl-4"
              style={mobileMenuSpring}
            >
              {routes.map((route) => (
                <MobileLink
                  href={route.url}
                  subitems={route.children}
                  key={route.id}
                  name={route.name}
                />
              ))}
            </animated.div>
          )}
        </div>
      </nav>
    </>
  );
}
