import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <div className="flex items-center">
      <Link to="/" className="my-0 py-0">
        <img className="w-18 h-8" src="/img/logo-final.png" alt="Logo" />
      </Link>
    </div>
  );
}
