import { twMerge } from "tailwind-merge";
import BlockQuote from "../../components/blockquote/BlockQuote";
import Card from "../../components/card/Card";
import "./ProfessionalPhotography.css";
import { useKeenSlider } from "keen-slider/react";
import Lightbox from "yet-another-react-lightbox";
import { useState } from "react";
import Captions from "yet-another-react-lightbox/plugins/captions";

export default function ProfessionalPhotography() {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        origin: "center",
        perView: 1,
        spacing: 16,
      },
      breakpoints: {
        "(min-width: 1024px)": {
          slides: {
            origin: "auto",
            perView: 1,
            spacing: 16,
          },
        },
      },
    },
    [],
  );
  function next() {
    instanceRef.current?.next();
  }
  function previous() {
    instanceRef.current?.prev();
  }

  function NavButton({ text, isPrevious, handler }) {
    return (
      <>
        <button
          aria-label={text}
          id="keen-slider-previous"
          className="size-8 rounded-full border border-labelleoffre-secondary bg-white text-labelleoffre-secondary transition hover:bg-labelleoffre-secondary hover:text-white md:size-12 md:p-3"
          onClick={handler}
        >
          <i
            className={twMerge(
              "fa-solid fa-chevron-right",
              isPrevious ? "rotate-180" : "rotate-0",
            )}
          />
          <span className="sr-only">{text}</span>
        </button>
      </>
    );
  }

  function ImageComparison({
    big = false,
    className,
    img1Src,
    img1Alt,
    img1Legend,
    img2Src,
    img2Alt,
    img2Legend,
    index,
  }) {
    return (
      <>
        <div
          className={twMerge(
            "gap-[1px] divide-y-8 divide-transparent md:grid md:grid-cols-2 md:divide-y-0",
            className,
          )}
        >
          <div>
            <img
              src={img1Src}
              alt={img1Alt}
              className={twMerge(
                "w-full cursor-pointer object-cover md:rounded-l-xl",
                big ? "h-96" : "h-60",
              )}
              onClick={() => {
                setCurrentIndex(index);
                setOpen(true);
              }}
            />
            <p className="w-full pt-1 text-center text-sm font-light text-gray-500 md:text-base">
              {img1Legend}
            </p>
          </div>
          <div>
            <img
              src={img2Src}
              alt={img2Alt}
              className={twMerge(
                "w-full cursor-pointer object-cover md:rounded-r-xl",
                big ? "h-96" : "h-60",
              )}
              onClick={() => {
                setCurrentIndex(index + 1);
                setOpen(true);
              }}
            />
            <p className="w-full pt-1 text-center text-sm font-light text-gray-500 md:text-base">
              {img2Legend}
            </p>
          </div>
        </div>
      </>
    );
  }
  const comparisonArray = [
    {
      id: 1,
      image1: {
        src: "/img/photography/lecosy-amateur-1.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/lecosy-pro-1.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
    {
      id: 2,
      image1: {
        src: "/img/photography/lecosy-amateur-2.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/lecosy-pro-2.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
    {
      id: 3,
      image1: {
        src: "/img/photography/buanderie-amateur.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/buanderie-pro.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
    {
      id: 4,
      image1: {
        src: "/img/photography/cuisine2-amateur.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/cuisine2-pro.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
    {
      id: 5,
      image1: {
        src: "/img/photography/cuisine-amateur.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/cuisine-pro.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
    {
      id: 6,
      image1: {
        src: "/img/photography/sdb-amateur.jpg",
        alt: "Cliché amateur",
        legend: (
          <>
            <i className="fa-solid fa-xmark text-error" /> Cliché amateur
          </>
        ),
      },
      image2: {
        src: "/img/photography/sdb-pro.jpg",
        alt: "Cliché de qualité professionnelle",
        legend: (
          <>
            <i className="fa-solid fa-check text-success" /> Cliché de qualité
            professionnelle
          </>
        ),
      },
    },
  ];
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // on transforme en tableau
  const lightboxImages = comparisonArray
    .map((comp) => [
      {
        src: comp.image1.src,
        alt: comp.image1.alt,
        title: comp.image1.alt,
      },
      {
        src: comp.image2.src,
        alt: comp.image2.alt,
        title: comp.image2.alt,
      },
    ])
    .reduce((a, b) => a.concat(b), []);
  return (
    <>
      <Lightbox
        plugins={[Captions]}
        index={currentIndex}
        open={open}
        close={() => setOpen(false)}
        slides={lightboxImages}
      />
      <div id="pro-photo" className="bg-white">
        <div className="">
          <div className="mx-auto max-w-screen-lg px-4 pb-10 pt-20">
            <div className="pb-10">
              <h1 className="pb-4 tracking-tighter text-labelleoffre-secondary">
                L'impact crucial de la photographie immobilière professionnelle
              </h1>
              <h2 className="font-light tracking-tight text-labelleoffre-tertiary">
                Des statistiques convaincantes
              </h2>
              <p className="pb-4 text-base font-light text-labelleoffre-secondary/90">
                Le recours à un photographe professionnel pour valoriser votre
                bien peut susciter des interrogations. Cependant, La Belle Offre
                a compilé des statistiques éloquentes prouvant l'essentielle
                contribution de la photographie professionnelle à la réussite
                des transactions immobilières. Décortiquons ces chiffres pour
                comprendre l'importance de ce choix.
              </p>
              <img
                src="/img/photography/paul-skorupskas-7KLa-xLbSXA-unsplash.jpg"
                className="h-80 w-full rounded-2xl object-cover"
                alt="Un cliché de lentille montrant la mer"
              />
            </div>

            <div className="gap-10 space-y-4 divide-y-8 divide-transparent md:grid md:grid-cols-2 md:space-y-0 md:divide-y-0">
              <Card
                key="card-1"
                className="col-span-2"
                big={true}
                icon={"fa-stopwatch-20"}
                title={"Vingt secondes pour convaincre"}
                text={
                  "En seulement 20 secondes, un acheteur décide s'il a un coup de cœur pour une annonce immobilière. Plus de 95% des utilisateurs basent leur jugement sur la première photo, soulignant l'importance cruciale des premières impressions. Le regard objectif d'un photographe professionnel permet de valoriser les atouts du bien sans préjugés, créant ainsi une première impression positive."
                }
              />

              <Card
                key="card-2"
                icon={"fa-image"}
                title={"Les acquéreurs aiment les photos"}
                text={
                  "Les futurs acquéreurs consacrent 60% de leur temps à examiner les photos d'une annonce immobilière, soulignant leur impact significatif. Des photos professionnelles attirent davantage l'attention et déclenchent rapidement la prise de contact."
                }
              />

              <Card
                key="card-3"
                icon={"fa-eye"}
                title={"Annonce 7 fois plus consultée"}
                text={
                  "Une annonce immobilière avec des photos professionnelles est 7 fois plus consultée que les autres. La qualité des images, avec leur cadrage précis, leur luminosité et leur netteté, rend le bien immobilier plus attractif."
                }
              />
            </div>

            <div className="my-8 rounded-3xl bg-labelleoffre-white p-6">
              <h2 className="pb-4 text-labelleoffre-secondary">Un exemple</h2>
              <ImageComparison
                index={0}
                img1Src={"/img/photography/lecosy-amateur-1.jpg"}
                img1Alt={"Cliché amateur"}
                img1Legend={
                  <>
                    <i className="fa-solid fa-xmark text-error" /> Cliché
                    amateur
                  </>
                }
                img2Src={"/img/photography/lecosy-pro-1.jpg"}
                img2Alt={"Cliché de qualité professionnelle"}
                img2Legend={
                  <>
                    <i className="fa-solid fa-check text-success" /> Cliché de
                    qualité professionnelle
                  </>
                }
              />
              <div className="pt-8 text-center">
                <a
                  href="#more-examples"
                  className="p-4 text-labelleoffre-tertiary transition-colors hover:text-labelleoffre-secondary"
                >
                  <i className="fa-solid fa-arrow-down" />
                  &nbsp; Voir d'autres exemples &nbsp;
                </a>
              </div>
            </div>

            <div className="gap-10 space-y-4 divide-y-8 divide-transparent md:grid md:grid-cols-2 md:space-y-0 md:divide-y-0">
              <Card
                key="card-4"
                icon={"fa-arrow-pointer"}
                title={"Taux de clics multiplié par 2,7"}
                text={
                  "Une étude de 2018 de SeLoger révèle que 4 photos professionnelles ou plus multiplient par 2,7 le taux de clic sur le bien, améliorant sa visibilité dans les résultats de recherche."
                }
              />

              <Card
                key="card-5"
                icon={"fa-gauge-high"}
                title={"Vente 3 fois plus rapide"}
                text={
                  "Des photos de qualité permettent à un bien immobilier de se vendre trois fois plus vite et à des prix plus élevés. Le savoir-faire d'un photographe professionnel, allant du choix de l'heure à la gestion des contre-jours, contribue à déclencher un coup de cœur au premier regard."
                }
              />

              <Card
                key="card-6"
                icon={"fa-calendar-days"}
                title={"Réduction du délai de vente"}
                text={
                  "Des photos de bonne qualité peuvent réduire le délai de vente de 21 jours, selon SeLoger. Bien que faire appel à un photographe professionnel implique un coût initial, ces images de qualité se démarquent parmi la multitude d'annonces en ligne."
                }
              />
              <Card
                key="card-7"
                icon={"fa-money-bill-trend-up"}
                title={
                  " 3,6 fois plus de ventes pour les professionnel de l’immobilier"
                }
                text={
                  "Pour les agences immobilières, l'engagement d'un photographe professionnel peut générer 3,6 fois plus de ventes par an, selon SeLoger."
                }
              />
            </div>

            <div className="py-8">
              <BlockQuote>
                La qualité visuelle devient ainsi un atout stratégique,
                propulsant le vendeur vers le succès dans ses transactions
                immobilières.
              </BlockQuote>
            </div>

            <p className="pb-8 text-lg font-light">
              En investissant dans des photos professionnelles, les vendeurs ne
              se contentent pas seulement d'améliorer l'esthétique de leur
              annonce, mais ils façonnent également une image de marque robuste,
              démontrant ainsi leur engagement envers la qualité. Cette démarche
              renforce la crédibilité du vendeur et instaure une confiance
              précoce chez les futurs acquéreurs. En effet, elle constitue un
              levier puissant pour affirmer leur positionnement sur le marché
              immobilier, marquant ainsi la différence dans un secteur
              compétitif.
            </p>
          </div>
          <div className="mt-8" id="more-examples">
            <section className="bg-labelleoffre-white p-2 pt-4 md:p-8">
              <div className="mx-auto max-w-[1340px]  ">
                {/* header */}
                <div className="flex items-end justify-between align-baseline">
                  <h2 className="m-0 text-3xl font-bold tracking-tighter text-labelleoffre-secondary sm:text-5xl">
                    Autres exemples
                  </h2>

                  {/* navigation */}
                  <div className="m-0 flex gap-1 md:mt-8 md:gap-2 lg:mt-0">
                    <NavButton
                      text="Comparison précédente"
                      isPrevious={true}
                      handler={previous}
                    />
                    <NavButton
                      text="Comparaison suivante"
                      isPrevious={false}
                      handler={next}
                    />
                  </div>
                </div>
                {/* slider */}
                <div className="mt-4 md:mt-8 lg:col-span-2 lg:mx-0">
                  <div className="keen-slider" ref={sliderRef}>
                    {comparisonArray.map((comp, i) => (
                      <>
                        <div
                          className="keen-slider__slide"
                          key={`slide-${comp.id}`}
                        >
                          <ImageComparison
                            index={2 * i}
                            big={true}
                            img1Src={comp.image1.src}
                            img1Alt={comp.image1.alt}
                            img1Legend={comp.image1.legend}
                            img2Src={comp.image2.src}
                            img2Alt={comp.image2.alt}
                            img2Legend={comp.image2.legend}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
