export default function MentionsLegales() {
  return (
    <div className="pt-14">
      <div className="container mx-auto px-4 py-8">
        <h1 className="pb-8 text-2xl text-labelleoffre-secondary">
          Mentions légales
        </h1>
        <p className="pb-8">
          {
            "Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site La belle offre l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi."
          }
        </p>
        <h2 className="pb-10 text-lg font-bold text-labelleoffre-secondary">
          {"Edition du site"}
        </h2>
        <p className="pb-10">
          {
            "Le présent site, accessible à l’URL app.labelleoffre.com (le « Site »), est édité par : LA BELLE OFFRE , société au capital de 1000 euros, inscrite au R.C.S. de NIMES sous le numéro 978 185 098 R.C.S. Nîmes, dont le siège social est situé au 10 Rue des Grillons, 30132 Caissargues, représenté(e) par Steven Quenea dûment habilité(e)"
          }
        </p>
        {/* TODO: à mettre à jour lorsqu'on saura qui héberge */}
        <h2 className="text-lg font-bold text-labelleoffre-secondary">
          Hébergement
        </h2>
        <p className="pb-6">
          {
            "Le Site est hébergé par la société Vercel, situé Washington, D.C., USA, (contact téléphonique ou email : +33664777978)."
          }
        </p>
        <h2 className="pt-3 text-lg font-bold text-labelleoffre-secondary">
          Directeur de publication
        </h2>
        <p className="pb-6">
          {"Le Directeur de la publication du Site est Steven Quenea."}
        </p>

        <h2 className="pt-3 text-lg font-bold text-labelleoffre-secondary">
          Nous contacter
        </h2>
        <ul className="list-inside list-disc pb-6">
          <li>{"Par téléphone : 04 65 84 85 56"}</li>
          <li>{"Par email : contact@labelleoffre.com"}</li>
        </ul>
        <h2 className="pt-3 text-lg font-bold text-labelleoffre-secondary">
          Données personnelles
        </h2>
        <p className="pb-28">
          {
            "Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section 'Charte de Protection des Données Personnelles', conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»)."
          }
        </p>
      </div>
    </div>
  );
}
