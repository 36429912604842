export default function QuizCTA() {
  function Button({ children, link }) {
    return (
      <a
        href={link}
        className={
          "cursor-pointer rounded-full bg-labelleoffre-tertiary px-12 py-3 font-Josefin text-lg font-medium text-white shadow transition-colors hover:bg-labelleoffre-secondary focus:outline-none focus:ring active:bg-labelleoffre-secondary"
        }
      >
        {children}
      </a>
    );
  }

  return (
    <>
      <div className="px-2 py-10">
        <div className="mx-auto max-w-screen-md rounded-3xl bg-labelleoffre-tertiary/60 text-center shadow-md">
          <div className="md:flex md:place-content-end">
            <div className="mx-auto flex-none p-8 md:max-w-[460px] md:p-14 md:pt-20">
              <h2 className="pb-2 text-2xl font-light tracking-tight text-white">
                Vous pensez pouvoir vendre seul&nbsp;?
              </h2>
              <h1 className="text-4xl tracking-tighter text-labelleoffre-secondary">
                Faites notre quiz&nbsp;!
              </h1>
              <p className="pt-4 text-lg font-light">
                Vous déterminerez si vous êtes prêt à vendre votre propriété
                seul ou si vous pourriez bénéficier de l'accompagnement de La
                Belle Offre.
              </p>
              <div className="pt-16 ">
                <Button link="/quiz">Je fais le test</Button>
              </div>
            </div>
            <img
              className="hidden md:flex md:w-60 md:grow md:rounded-e-3xl"
              src="/img/quiz-cta/dollar-gill-Kyoshy7BJIQ-unsplash.jpg"
              alt="Homme lisant un livre"
            />
          </div>
        </div>
      </div>
    </>
  );
}
