import { NavLink } from "react-router-dom";
import { useInView, animated } from "@react-spring/web";
import { twMerge } from "tailwind-merge";
import { plansData } from "./plans-data";
import { useState } from "react";

export default function Plans() {
  const [animatedDivRef, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true, rootMargin: "-10% 0%" },
  );

  function PlanFeatures({ features, highlighted }) {
    return (
      <ul className="flex-1 divide-y-[0.9rem] divide-transparent text-white">
        {features.map((feature, i) => (
          <PlanFeatureItem
            feature={feature}
            key={`feature-${i}`}
            highlighted={highlighted}
          />
        ))}
      </ul>
    );
  }
  function PlanFeatureItem({ feature, highlighted }) {
    return (
      <li className="flex items-start">
        <i
          className={twMerge(
            "fa-solid fa-circle-check text-base ",
            highlighted === true ? "" : "",
          )}
        />
        <span className="ml-3 text-base font-light">{feature}</span>
      </li>
    );
  }
  function PlanSubscribe({ id, price, highlighted }) {
    return (
      <div className="flex-shrink-0 pt-4 text-center">
        <NavLink
          to={`/formule/${id}`}
          className={twMerge(
            "inline-flex w-full max-w-xs items-center justify-center rounded-full border bg-labelleoffre-primary px-4 py-2 font-Josefin text-xl font-medium tracking-tight text-labelleoffre-secondary transition-colors hover:bg-labelleoffre-secondary hover:text-labelleoffre-white focus:outline-none focus:ring-1 focus:ring-labelleoffre-secondary focus:ring-offset-2",
            highlighted
              ? "border-2 border-labelleoffre-secondary bg-white shadow-md"
              : "",
          )}
        >
          Je découvre
        </NavLink>
      </div>
    );
  }
  function PlanOptions({ options }) {
    const [open, setOpen] = useState(false);
    return (
      options && (
        <>
          <h3
            className="cursor-pointer select-none text-xl font-medium text-white transition-colors hover:text-labelleoffre-white"
            onClick={() => setOpen(!open)}
          >
            <i
              className={twMerge(
                "fa-solid fa-chevron-down transition-transform",
                open ? "rotate-180" : "",
              )}
            />
            &nbsp;{options.title}
          </h3>
          {
            <table
              class={twMerge(
                " table-auto scale-0 text-base font-light text-white transition-transform md:text-sm",
                open ? "scale-1" : "hidden",
              )}
            >
              <tbody className="divide-y divide-white/20">
                {options.items.map((o) => (
                  <tr className="odd:bg-transparent even:bg-white/10">
                    <td className="px-1 md:px-2 md:py-1">
                      {o.name}
                      {o.rule && (
                        <>
                          <br />
                          <span className="text-sm">
                            {o.rule}
                            {o.priceMore && <>,&nbsp;{o.priceMore}</>}
                          </span>
                        </>
                      )}
                    </td>
                    <td className="px-1 py-1 md:px-2">
                      <span>{o.price}&nbsp;€</span>&nbsp;
                      <span className="align-super text-xs md:text-xs">
                        TTC
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </>
      )
    );
  }

  return (
    <section
      key="plans-section"
      id="formules"
      className="bg-labelleoffre-primary py-4 sm:py-10"
    >
      <animated.div
        className="mx-auto px-2 md:max-w-md lg:max-w-screen-lg xl:max-w-screen-xl"
        style={springs}
        ref={animatedDivRef}
      >
        <h1 className="pb-2 pt-0 text-center tracking-tighter text-labelleoffre-secondary">
          Nos formules
        </h1>
        <div className="mt-6 flex flex-col items-center justify-center gap-2 space-y-8 lg:flex-row lg:items-stretch lg:space-x-4 lg:space-y-0">
          {plansData
            .sort((a, b) => a.order - b.order)
            .map((plan) => (
              <div
                key={plan.id}
                className={twMerge(
                  "flex w-full max-w-sm flex-col space-y-6 rounded-3xl border-2 border-labelleoffre-tertiary p-8 shadow-sm transition-shadow md:max-w-2xl",
                  plan.highlighted === true
                    ? "border-4 bg-labelleoffre-tertiary/70 shadow-xl"
                    : "bg-labelleoffre-secondary/90",
                )}
              >
                <div className="flex-shrink-0 space-y-2 pb-4 text-center text-white">
                  <h2 className="m-0 pb-0 pt-0 text-4xl leading-tight tracking-tighter text-white">
                    {plan.name}
                  </h2>
                  <p className="pb-0 pt-0 text-base font-light tracking-widest text-white">
                    {plan.subtitle}
                  </p>

                  <p className="py-0 text-lg font-light">{plan.discretion}</p>

                  <h2
                    className={twMerge(
                      "pt-4 text-5xl font-medium text-white",
                      plan.highlighted ? "text-labelleoffre-primary" : "",
                    )}
                  >
                    {plan.price > 0 && !plan.isPriceStartingFrom && (
                      <>
                        <span>{plan.price}&nbsp;€</span>
                        <span className="align-super text-lg">TTC</span>
                      </>
                    )}
                    {plan.price > 0 && plan.isPriceStartingFrom && (
                      <>
                        <span className="text-lg">à partir de&nbsp;</span>
                        <span>{plan.price}&nbsp;€</span>
                        <span className="align-super text-lg">TTC</span>
                      </>
                    )}
                  </h2>
                  <PlanSubscribe
                    id={plan.id}
                    price={plan.price}
                    highlighted={plan.highlighted}
                  />
                </div>

                <PlanFeatures
                  features={plan.features}
                  highlighted={plan.highlighted}
                />
                <PlanOptions options={plan.options} />
              </div>
            ))}
        </div>
      </animated.div>
    </section>
  );
}
