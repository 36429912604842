export const items = [
  {
    id: 1,
    title: <>Une nouvelle façon de vendre&nbsp;: le coaching immobilier</>,
    content: `Découvrez une toute nouvelle approche innovante du marché immobilier. Notre coaching dédié vous accompagne pas à pas pour une vente autonome réussie.`,
    image: {
      url: "/img/hero/florian-schmidinger-b_79nOqf95I-unsplash.jpg",
      alt: "Un coaching dédié",
    },
    cta: {
      text: "En savoir plus",
      url: "formules",
    },
  },

  {
    id: 2,
    title: <>Optimisez votre vente</>,
    content: `72% des ventes entre particuliers échouent! Maximisez vos chances de succès, économisez du temps et augmentez vos profits tout en évitant les pièges!`,
    image: {
      url: "/img/hero/avi-werde-hHz4yrvxwlA-unsplash.jpg",
      alt: "Sed et pharetra sapien",
    },
    cta: {
      text: "En savoir plus",
      url: "formules",
    },
  },

  {
    id: 3,
    title: <>Vendez en toute confiance et sans contrainte</>,
    content: `Conservez votre liberté totale en tant que vendeur. Avec notre coaching en immobilier, aucun mandat à signer, aucune commission à payer et aucune exclusivité exigée.`,
    image: {
      url: "/img/hero/r-architecture-2gDwlIim3Uw-unsplash.jpg",
      alt: "Vestibulum ultrices in lectus quis sollicitudin",
    },
    cta: {
      text: "En savoir plus",
      url: "formules",
    },
  },

  {
    id: 4,
    title: <>Préparez votre vente immobilière avec soin</>,
    content: `Une vente immobilière réussie nécessite une préparation minutieuse. Anticipez chaque situation potentielle et assurez-vous une transaction sans difficulté. Notre approche personnalisée vous offre la clé du succès.`,
    image: {
      url: "/img/hero/photo-1580587771525-78b9dba3b914.avif",
      alt: "Une vente immobilière réussie",
    },
    cta: {
      text: "En savoir plus",
      url: "formules",
    },
  },
];
