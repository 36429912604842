import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import TableOfContents from "../../components/toc/TableOfContents";
import Card from "../../components/card/Card";

export default function HomeStaging() {
  function ImageComparison({ img1url, img2url, caption }) {
    return (
      <div className="my-4">
        <div>
          <ReactCompareSlider
            className="rounded-md"
            itemOne={
              <ReactCompareSliderImage src={img1url} alt="Etat actuel" />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={img2url}
                alt="Après home staging virtuel"
              />
            }
          />
        </div>
        <p className="pt-1 text-center text-sm font-light text-gray-500 md:text-base">
          {caption}
        </p>
      </div>
    );
  }

  return (
    <section id="homestaging" className="bg-white pb-10 pt-20">
      <div className="mx-auto max-w-screen-md px-4">
        <h1 className="pb-0 font-bold tracking-tighter text-labelleoffre-secondary">
          Home staging virtuel en 3D
        </h1>
        <h2 className="pt-2 text-3xl font-light tracking-tight text-labelleoffre-tertiary">
          Transformez l'ordinaire en remarquable pour une vente immobilière
          efficace
        </h2>
        <p className="text-xl font-light text-gray-700">
          Faites de votre propriété une expérience visuelle mémorable et
          renforcez son positionnement sur le marché immobilier.
        </p>

        <ImageComparison
          img1url={"/img/homestaging/etat_actuel1.jpg"}
          img2url={"/img/homestaging/visuel3D_1.jpg"}
          caption={"A gauche, l'état actuel et à droite, la projection 3D"}
        />

        <TableOfContents
          className="top-16 my-8 rounded-xl bg-labelleoffre-tertiary-light/90 p-4 text-4xl backdrop-blur-md"
          label="Sommaire"
          isOpen={false}
        />

        <h2
          className="pb-2 pt-4 tracking-tight text-labelleoffre-secondary"
          id="transformation-visuelle-3d"
        >
          Transformation Visuelle en 3D
        </h2>
        <p className="text-lg font-light">
          Notre home staging virtuel en 3D insuffle une nouvelle vie à des
          intérieurs imaginaires, transcendant le banal pour atteindre
          l'extraordinaire. Les espaces sont numériquement transformés pour
          créer une expérience visuelle immersive. Grâce à notre Home Staging
          Virtuel Interactif, découvrez un avant/après captivant avec une
          superposition parfaite entre la photo existante de votre propriété et
          le projet 3D.
        </p>

        <ImageComparison
          img1url={"/img/homestaging/chambre_etat_actuel1.jpg"}
          img2url={"/img/homestaging/chambre_visuel3D_1.jpg"}
          caption={"A gauche l'état actuel, à droite après homestaging 3D"}
        />

        <h2
          className="pb-2 pt-8 tracking-tight text-labelleoffre-secondary"
          id="objectifs-home-staging"
        >
          Les objectifs du home staging en immobilier
        </h2>

        <div className="space-y-4 md:grid md:grid-cols-3 md:gap-2 md:space-y-0 md:pb-4 md:pt-2">
          <Card
            icon="fa-heart"
            title="Créer le coup de cœur"
            text="Notre objectif est de susciter un coup de cœur chez les acheteurs potentiels en mettant en avant le potentiel esthétique de chaque propriété."
          />
          <Card
            icon="fa-clock"
            title="Éviter les négociations"
            text="En dévoilant un intérieur attrayant dès le départ, le home staging virtuel contribue à minimiser les négociations en démontrant la véritable valeur de la propriété."
          />
          <Card
            icon="fa-forward"
            title="Vendre rapidement"
            text="Les statistiques indiquent qu'une résidence valorisée peut se vendre jusqu'à 5 fois plus rapidement et à un prix 25% supérieur, ainsi un environnement propice à l'achat."
          />
        </div>

        <ImageComparison
          img1url={"/img/homestaging/sam_etat_actuel.jpg"}
          img2url={"/img/homestaging/sam_visuel3D.jpg"}
          caption={"Avant/après homestaging virtuel 3D"}
        />

        <h2
          id="valorisation-immo"
          className="pb-2 pt-8 tracking-tight text-labelleoffre-secondary"
        >
          Valorisation immobilière
        </h2>
        <p className="text-lg font-light">
          Une propriété judicieusement valorisée élimine les hésitations des
          acheteurs à entreprendre des travaux mineurs, élargissant ainsi le
          pool des acheteurs potentiels et permettant une vente à un prix plus
          élevé.
        </p>

        <h2
          id="revolution"
          className="pb-2 pt-8 tracking-tight text-labelleoffre-secondary"
        >
          Une révolution
        </h2>
        <p className="text-lg font-light">
          Introduisant une révolution dans le secteur immobilier, notre service
          de home staging virtuel en 3D offre une approche novatrice pour
          métamorphoser des espaces ordinaires en intérieurs remarquables.
          Explorez comment cette solution répond de manière exceptionnelle à vos
          besoins et objectifs de vente à travers notre approche de coaching
          personnalisé.
        </p>
        <p className="text-lg font-light">
          Cette présentation offre une représentation visuelle puissante de la
          transformation, permettant une compréhension concrète de
          l'amélioration potentielle de chaque espace.
        </p>

        <h2
          id="conclusion"
          className="pb-2 pt-8 tracking-tight text-labelleoffre-secondary"
        >
          En conclusion
        </h2>
        <p className="text-lg font-light">
          En résumé, notre service de home staging virtuel en 3D représente une
          solution pratique et visuellement convaincante pour maximiser
          l'attrait d'une propriété, que ce soit dans le contexte d'une vente
          immobilière ou simplement pour la satisfaction de redéfinir
          l'esthétique intérieure. Faites de votre propriété une expérience
          visuelle mémorable et renforcez son positionnement sur le marché
          immobilier.
        </p>
      </div>
    </section>
  );
}
