import { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./Savings.css";

export default function Savings() {
  const nf = new Intl.NumberFormat("fr-FR", {
    currency: "EUR",
    style: "currency",
  });

  const min = 50_000;
  const max = 1_000_000;

  const [value, setValue] = useState([0, 500_000]);

  return (
    <>
      <section className="mx-auto max-w-full px-2 pb-16 pt-20 md:max-w-screen-lg">
        <h1 className="pb-8 text-center font-bold tracking-tighter text-labelleoffre-secondary">
          Découvrez vos économies !
        </h1>
        <div className="mx-auto rounded-3xl bg-labelleoffre-tertiary-light p-10 md:max-w-screen-lg">
          <div className="w-100 flex flex-col items-center justify-center">
            <h3 className="text-center font-Josefin text-xl text-labelleoffre-secondary/90">
              Prix de vente de votre bien&nbsp;:&nbsp;
              {nf.format(value[1])}
            </h3>
            <div className="my-5 flex w-full flex-col items-center gap-4 md:flex-row">
              <p>{nf.format(min)}</p>
              <RangeSlider
                className="single-thumb"
                min={min}
                max={max}
                value={value}
                thumbsDisabled={[true, false]}
                onInput={setValue}
                rangeSlideDisabled={true}
                step={1000}
              />
              <p>{nf.format(max)}</p>
            </div>
            <h2 className="text-center text-2xl text-labelleoffre-secondary">
              Voici ce qu'il vous reste :{" "}
            </h2>
            <h1 className="text-center font-bold text-labelleoffre-tertiary">
              {nf.format(value[1] - 3498)}
            </h1>
          </div>
        </div>
      </section>
    </>
  );
}
