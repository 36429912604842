import Card from "../../components/card/Card";

export function VirtualTour() {
  function CallToAction() {
    return (
      <>
        {/* Vue mobile */}
        <div className="my-8 block rounded-3xl bg-labelleoffre-tertiary-light/70 shadow-md md:hidden">
          <img
            src="/img/virtual-tour/Appartement-Le-Cosy-AirBnB-Living-Room-1.jpg"
            alt="Vue sur la pièce prise par notre numériseur Matterport"
            className="rounded-t-3xl"
          />
          <div className="p-2">
            <h2 className="text-2xl font-medium tracking-tighter text-labelleoffre-secondary">
              Un exemple avec Matterport
            </h2>
            <p className="pb-4 text-base font-light text-labelleoffre-tertiary">
              Voyez par vous même ce qu'il est possible de faire avec
              Matterport&nbsp;!
            </p>
            <div className="my-4 text-center">
              <a
                href="https://my.matterport.com/show/?m=siUTuRcf3qu"
                target="_blank"
                rel="noreferrer"
                className="rounded-full bg-labelleoffre-tertiary/80 p-4 font-Josefin"
              >
                Voir un exemple&nbsp;
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Vue desktop */}
        <div className="mx-auto my-8 hidden w-fit rounded-3xl bg-labelleoffre-tertiary/80 p-4 shadow-md md:mt-12 md:block">
          <h2 className="text-white">Un exemple avec Matterport</h2>
          <p className="pb-4 pt-2 text-lg font-light text-white">
            Voyez par vous même ce qu'il est possible de faire avec
            Matterport&nbsp;!
          </p>
          <iframe
            title="Intégration de la visite virtuelle de l'appartement Le Cosy"
            width="853"
            height="480"
            src="https://my.matterport.com/show/?m=siUTuRcf3qu"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
            className=" mx-auto rounded-3xl"
          ></iframe>
        </div>
      </>
    );
  }

  return (
    <section id="virtual-tour" className="mx-auto max-w-screen-lg pb-14 pt-20">
      <div className="px-4">
        <h1 className="tracking-tighter text-labelleoffre-secondary">
          Accroître vos chances de vente
        </h1>
        <h2 className="pb-2 pt-4 font-light tracking-tight text-labelleoffre-tertiary">
          La visite virtuelle, votre atout majeur avec notre matériel Matterport
        </h2>
        <div className="gap-14 py-4 md:flex md:flex-row-reverse">
          <div className="flex flex-col justify-start gap-4 pb-4 md:pb-0">
            <p className="text-lg font-light text-black/80 md:text-xl">
              Plongez dans une ère révolutionnaire de l'immobilier, où la
              technologie redéfinit de manière significative la façon dont nous
              explorons et concluons des transactions immobilières.
            </p>
            <p className="text-lg font-light text-black/80 md:text-xl">
              La visite virtuelle immobilière se profile comme une tendance
              incontournable, offrant une expérience immersive qui transcende
              les frontières du conventionnel.
            </p>
          </div>
          <img
            src="/img/virtual-tour/iStock-1442692785.jpg"
            alt="Homme réalisant une numérisation de pièce avec l'outil Matterport"
            className=" w-[500px] rounded-md object-cover object-top"
          />
        </div>

        <h2
          id="data-speak-for-themselves"
          className="pb-2 pt-4 tracking-tight text-labelleoffre-tertiary"
        >
          Les données parlent d'elles-mêmes
        </h2>

        <div className="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
          <Card
            icon="fa-chart-line"
            title="Augmentation des ventes"
            text="Les annonces incluant une visite virtuelle génèrent en
            moyenne de 3 à 5 fois plus de contacts que celles sans. Ces chiffres
            probants sont issus d'une source fiable, à savoir une publication de
            CAFPI datée du 19 décembre 2022, garantissant ainsi un taux de
            conversion élevé."
          />
          <Card
            icon="fa-clock"
            title="Économies de temps significatives"
            text="Les acheteurs potentiels consacrent en moyenne 20% de temps
            en moins aux visites physiques grâce aux visites virtuelles, leur
            permettant de se concentrer sur des biens mieux adaptés à leurs
            besoins."
          />
          <Card
            icon="fa-earth"
            title="Élargissement de la portée mondiale"
            text="Les visites virtuelles transcendent les frontières
            physiques. Une étude de Matterport révèle que les visites virtuelles
            génèrent 49% de vues en plus sur les annonces, attirant ainsi un
            public international."
          />
          <Card
            icon="fa-handshake"
            title="Confiance accrue des acheteurs"
            text="En offrant une expérience immersive, les visites virtuelles
            renforcent la confiance des acheteurs."
          />
        </div>

        <CallToAction />

        <h2
          id="increase-sales"
          className="pb-2 pt-4 tracking-tight text-labelleoffre-tertiary"
        >
          Accroître vos chances de vente&nbsp;: la visite virtuelle, votre atout
          majeur
        </h2>
        <p className="pb-4 pt-2 text-lg font-light">
          Par-delà les avantages évidents tels que l'économie de temps et la
          portée mondiale, la visite virtuelle constitue un atout inestimable
          pour les vendeurs immobiliers, accroissant considérablement les
          chances de conclure une vente.
        </p>
        <p className="pb-4 pt-2 text-lg font-light">
          Comment ? En éliminant les barrières géographiques et en permettant
          aux acheteurs potentiels de valider leur intérêt sans avoir à se
          déplacer physiquement.
        </p>
        <h2
          id="advantages"
          className="pb-2 pt-4 tracking-tight text-labelleoffre-tertiary"
        >
          Les avantages incontestables
        </h2>
        <div className="space-y-4 md:grid md:grid-cols-3 md:gap-4 md:space-y-0">
          <Card
            icon="fa-1"
            title="Élimination des distances"
            text={
              "Grâce à la visite virtuelle, les personnes intéressées n'ont plus besoin de parcourir des distances considérables pour explorer votre propriété, élargissant ainsi significativement votre bassin d'acheteurs potentiels, attirant des investisseurs et des acquéreurs de différentes régions, voire de l'étranger."
            }
          />
          <Card
            icon="fa-2"
            title="Confirmation avant la visite physique"
            text={
              "Les acheteurs peuvent visualiser chaque recoin de la propriété à travers la visite virtuelle, confirmant ainsi leur intérêt avant de planifier une visite physique. Cela garantit que les visites sur place sont effectuées par des personnes déjà convaincues de la pertinence du bien par rapport à leurs critères."
            }
          />
          <Card
            icon="fa-3"
            title="Réduction des visites non qualifiées"
            text={
              "En offrant une expérience immersive en ligne, la visite virtuelle filtre les acheteurs qui ne sont pas en phase avec la propriété, réduisant ainsi le nombre de visites non qualifiées. Cela économise non seulement le temps du vendeur, mais également celui des acheteurs potentiels."
            }
          />
        </div>
      </div>
    </section>
  );
}
