import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import Nav from "../components/nav/Nav";

export default function Layout() {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
    </>
  );
}
