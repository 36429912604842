import { useParams } from "react-router-dom";
import { plansData } from "../home/plans/plans-data";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";

export default function PlanView() {
  function PlanFeatures({ features }) {
    return (
      <ul className="flex-1 space-y-2 text-base md:space-y-4 md:text-lg">
        {features.map((feature, i) => (
          <li className="flex items-start" key={`feature-${i}`}>
            <i className="fa-solid fa-check-circle text-base text-labelleoffre-tertiary" />
            <span className="ml-3 font-light ">{feature}</span>
          </li>
        ))}
      </ul>
    );
  }
  function PlanOptions({ options }) {
    return (
      options && (
        <div className="pb-2">
          <h3 className="pb-2 text-2xl font-medium text-labelleoffre-tertiary">
            {options.title}&nbsp;:
          </h3>
          <p className="pb-2 text-sm font-light">{options.warning}</p>
          <table class="table-auto  text-base font-light md:text-lg">
            <tbody className="divide-y divide-labelleoffre-tertiary">
              {plan.options.items.map((o) => (
                <tr className="odd:bg-white even:bg-labelleoffre-tertiary-light/40">
                  <td className="px-1 md:px-2 md:py-1">
                    {o.name}
                    {o.rule && (
                      <>
                        <br />
                        <span className="text-sm">
                          {o.rule}
                          {o.priceMore && <>,&nbsp;{o.priceMore}</>}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="px-1 py-1 md:px-2">
                    <span>{o.price}&nbsp;€</span>&nbsp;
                    <span className="align-super text-xs md:text-sm">TTC</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    );
  }
  function PaymentConditions({ conditions }) {
    return (
      <div className="divide-y-4 divide-transparent pt-6">
        <h2 className="text-lg">Conditions de Paiement</h2>
        {conditions.map((c) => (
          <p className="text-sm font-light">{c}</p>
        ))}
        <p className="text-sm font-light">
          * Le prix est indiqué toutes taxes comprises.
        </p>
        <p className="text-sm font-light">
          <a
            href="/cgu#cgv"
            className="text-labelleoffre-tertiary hover:font-medium hover:text-labelleoffre-secondary"
          >
            Voir CGV.
          </a>
        </p>
      </div>
    );
  }
  function PlanCTA() {
    return (
      <>
        <a href="/contact">
          <div className="mx-auto flex cursor-pointer rounded-xl bg-labelleoffre-tertiary shadow-md transition-transform hover:translate-x-[2px] hover:translate-y-[2px] hover:shadow-none md:grid-cols-2">
            <img
              src="/img/plans/bruce-mars-8YG31Xn4dSw-unsplash.jpg"
              alt="Homme content"
              className="m-[4px] hidden size-60 shrink-0 rounded-l-xl object-cover md:block"
            />
            <div className="grid flex-1 grid-rows-2 space-y-2 p-8 text-center">
              <h2 className="text-white">
                <i className="fa-solid fa-lightbulb animate-pulse" />
                &nbsp;Cette formule vous intéresse&nbsp;?
              </h2>
              <p className="text-lg">
                Cliquez ici pour nous contacter directement via le formulaire de
                contact&nbsp;!
              </p>
            </div>
          </div>
        </a>
      </>
    );
  }
  function MainFeature({ title, description }) {
    return (
      <>
        <div className="rounded-md bg-labelleoffre-tertiary/20 p-4 text-center">
          <h3 className="pb-2 pt-0 text-base font-medium sm:text-lg xl:text-xl">
            {title}
          </h3>
          <p className="text-base font-light">{description}</p>
        </div>
      </>
    );
  }
  function PlanDetails({ plan }) {
    return (
      <>
        <ParallaxBanner
          layers={[
            {
              image: "/img/plans/vitaly-gariev-7Mx-hwRb5H4-unsplash.jpg",
              speed: -10,
            },
          ]}
          className="relative -mx-4 my-8 min-h-56 md:mx-0 md:rounded-r-3xl"
        >
          <div className="absolute inset-0 bg-white/90 sm:bg-gradient-to-r md:bg-transparent md:from-white md:via-white/90 md:to-transparent" />
          <div className="relative mx-auto max-w-screen-xl px-4 py-6 sm:px-6 lg:flex  lg:items-center lg:px-8">
            {plan.detail && (
              <>
                <div className="my-6 block items-center gap-4 md:grid md:grid-cols-2">
                  <div className="space-y-3">
                    {plan.subtitleLong && (
                      <h2 className="text-2xl font-medium tracking-tighter text-labelleoffre-secondary md:text-3xl">
                        {plan.subtitleLong}
                      </h2>
                    )}
                    <p className="text-left text-lg font-light tracking-normal text-labelleoffre-tertiary md:text-xl">
                      {plan.detail}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </ParallaxBanner>
      </>
    );
  }

  const { id } = useParams();

  const plan =
    plansData.filter((x) => x.id === id)?.length === 1
      ? plansData.filter((x) => x.id === id)[0]
      : undefined;

  return (
    <>
      <div className="mx-auto max-w-screen-lg px-4 pb-10 pt-16">
        {!plan ? (
          <>
            <p>Formule introuvable</p>
          </>
        ) : (
          <>
            {/* bandeau/en-tête formule */}
            <ParallaxProvider>
              <ParallaxBanner
                layers={[
                  {
                    image: plan.background,
                    speed: -30,
                  },
                ]}
                className=" my-4 flex place-content-center rounded-3xl bg-cover bg-center bg-no-repeat p-8 text-left align-middle backdrop-blur-sm md:my-10"
              >
                <div className=" absolute inset-0 z-0 rounded-3xl bg-labelleoffre-white/80 bg-gradient-to-tr from-labelleoffre-tertiary/10 to-white/10" />

                <div className="relative mx-auto flex-1">
                  <h1 className="text-3xl font-bold tracking-tight text-labelleoffre-secondary md:text-6xl md:tracking-tighter">
                    {plan.name}
                  </h1>
                  <h2 className="text-xl font-medium leading-none tracking-tight text-labelleoffre-tertiary md:text-4xl md:tracking-tighter">
                    {plan.subtitle}
                  </h2>
                </div>

                <div className="z-10 shrink-0">
                  <p className="text-right font-light">
                    {plan.price > 0 && !plan.isPriceStartingFrom
                      ? "Votre formule pour"
                      : "A partir de"}
                  </p>
                  <h2 className="font-medium md:text-5xl">
                    {plan.price}€
                    <span className="align-super text-base">*</span>
                  </h2>
                </div>
              </ParallaxBanner>

              {/* description & points principaux */}
              <div className="my-12 divide-y-8 divide-transparent md:flex md:gap-4 md:divide-y-0">
                <div className="md:w-96 md:shrink-0">
                  <p className="text-base md:text-lg">Description</p>
                  <p className="text-base font-light md:text-lg">
                    {plan.discretionLong}
                  </p>
                </div>
                <div className="grid flex-1 gap-2 md:grid-cols-2">
                  {plan.mainFeatures &&
                    plan.mainFeatures.map((mf, i) => (
                      <MainFeature
                        title={mf.title}
                        description={mf.text}
                        key={`main-feature-${i}`}
                      />
                    ))}
                </div>
              </div>

              {/* plan detail & subtitle */}
              <PlanDetails plan={plan} />

              <div className="pb-6">
                <p className="pb-4 text-lg">Votre formule en détail...</p>
                {!plan.detailedFeatures ? (
                  <PlanFeatures features={plan.features} />
                ) : (
                  <PlanFeatures features={plan.detailedFeatures} />
                )}
              </div>

              <div className="mb-8 mt-6">
                <PlanCTA />
              </div>
              <PlanOptions options={plan.options} />
              <PaymentConditions conditions={plan.paymentConditions} />
            </ParallaxProvider>
          </>
        )}
      </div>
    </>
  );
}
