import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import './tailwind.output.css';
import App from './App';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}