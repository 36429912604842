export const routes = [
  {
    id: "formules",
    name: "Nos formules",
    url: "/#formules",
    type: "link",
  },
  {
    id: "quiz",
    name: "Quiz",
    url: "/quiz",
    type: "link",
  },
  {
    id: "valorisation",
    name: "Mise en valeur",
    type: "submenu",
    isOpen: false,
    children: [
      {
        id: "photo",
        name: "L'art de la photo",
        url: "/photo-pro",
        type: "link",
      },
      {
        id: "visite-virtuelle",
        name: "Visite virtuelle",
        url: "/visite-virtuelle",
        type: "link",
      },
      {
        id: "homestaging",
        name: "Home Staging",
        url: "/homestaging",
        type: "link",
      },
    ],
  },

  {
    id: "about",
    name: "A propos",
    url: "/a-propos",
    type: "link",
  },
  {
    id: "contact",
    name: "Nous contacter",
    url: "/contact",
    type: "link",
  },
];
