import { twMerge } from "tailwind-merge";

export default function BlockQuote({ children, author, className }) {
  return (
    <div
      className={twMerge(
        "my-4 rounded-md bg-labelleoffre-tertiary bg-gradient-to-tr from-labelleoffre-tertiary to-labelleoffre-secondary/50 p-2 text-labelleoffre-white shadow-md md:p-4",
        className,
      )}
    >
      <p>
        <i className="fa-solid fa-quote-left italic" />
      </p>
      <p className="px-2 text-justify font-Josefin text-2xl font-bold italic md:px-4">
        {children}
      </p>
      <p className="text-right">
        <i className="fa-solid fa-quote-right italic" />
      </p>
      {author && <p>{author}</p>}
    </div>
  );
}
