export const TestimonialsData = [
  {
    title: ``,
    description: `Je recommande vivement La Belle Offre à ceux qui recherchent une entreprise fiable et digne de confiance, Steven sait faire preuve de professionnalisme et de réactivité avec ses clients. N’hésitez pas à faire appel à ses services !`,
    rating: 5,
    author: `Léa Roure`,
  },
  {
    title: ``,
    description: `Je recommande vivement la belle offre, steven j'ai fais appel à ces services et j'ai recommandé steven à un de mes client qui a été ravis de travailler avec lui. Steven est passionné et habité par son métier. Son conseil va au dela de sa prestation car il arrive à nous projeter après la vente de notre bien. A très bientôt`,
    rating: 5,
    author: `Anthony da Silva Amorin`,
  },
  {
    title: ``,
    description: `M. Quenea est très professionnel et pertinent dans ses conseils. Un grand merci pour cet enrichissement ! 😉`,
    rating: 5,
    author: `ABF Formation`,
  },
  {
    title: ``,
    description: `Très professionnel, très efficace et super sympa !!`,
    rating: 5,
    author: `Bernadette Florance`,
  },
  {
    title: ``,
    description: `Je recommande La Belle Offre. Nous avons longuement réfléchi pour un coaching personnel au final nous somme partis sur la formule pack premium et M. Quenea nous a bien aidé. De très bon conseil, très professionnel.`,
    rating: 5,
    author: `Styfler`,
  },
  {
    title: ``,
    description: `Je recommande Mr Quenea sérieux et très l’écoute, merci pour vos conseils.`,
    rating: 5,
    author: `Marion Combier`,
  },
];
