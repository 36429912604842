import { ParallaxBanner } from "react-scroll-parallax";

export default function CallToAction() {
  return (
    <>
      <section>
        <div className="mx-auto max-w-screen-2xl px-4 py-8 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 rounded-3xl md:grid-cols-2 md:gap-0">
            <div className="rounded-l-3xl rounded-r-3xl bg-labelleoffre-tertiary-light p-6 md:rounded-r-none md:p-12 lg:px-16 lg:py-24">
              <div className="mx-auto max-w-xl text-center text-lg text-labelleoffre-secondary/90 md:text-left">
                <h2 className="text-2xl font-bold tracking-normal md:text-4xl md:tracking-tight">
                  Réservez votre appel découverte gratuit et éclairez votre
                  projet immobilier&nbsp;!
                </h2>
                <p className="hidden sm:mt-4 sm:block">
                  La vente d'un bien immobilier demande une préparation
                  minutieuse pour réussir pleinement, ce qui peut vite devenir
                  décourageant si cela n'est pas anticipé.
                </p>
                <p className="hidden sm:mt-4 sm:block">
                  Vous avez des questions ou des incertitudes&nbsp;? Faisons
                  ensemble le point sur votre projet. Réservez dès maintenant un
                  appel découverte pour bénéficier d'un service gratuit et sans
                  engagement.
                </p>
                <p className="hidden sm:mt-4 sm:block">
                  Assurez-vous de vendre dans les meilleures conditions
                  possibles.
                </p>

                <div className="mx-auto mt-4 text-center md:mt-10">
                  <a
                    href="https://calendly.com/steven-quenea/30min"
                    className="inline-block rounded-full bg-labelleoffre-secondary px-12 py-3 font-Josefin text-base font-medium text-white shadow transition hover:bg-labelleoffre-tertiary hover:text-labelleoffre-secondary hover:shadow-md focus:outline-none focus:ring focus:ring-labelleoffre-secondary md:text-lg"
                  >
                    <i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;Je
                    réserve un appel téléphonique
                  </a>
                </div>
              </div>
            </div>

            <ParallaxBanner
              layers={[
                {
                  image: "/img/home/iStock-1329762915-2.jpg",
                  speed: -10,
                },
              ]}
              className="h-40 w-full overflow-y-hidden rounded-l-3xl rounded-r-3xl object-cover sm:h-56 md:h-full md:rounded-l-none"
            />
          </div>
        </div>
      </section>
    </>
  );
}
