import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { questions } from "./QuizData";
import { useConfetti } from "@stevent-team/react-party";

export default function Quiz() {
  const rules = [
    {
      result: 0,
      why: `Si la plupart des réponses sont des "A", vous pourriez dire`,
      explaination:
        "Félicitations ! Vous semblez avoir une bonne maîtrise de la vente immobilière et pourriez probablement réussir à vendre votre bien seul. Cependant, avec La Belle Offre, vous pouvez économiser un temps considérable. Grâce à nos outils et ressources, nous réduisons jusqu'à 80% les visites infructueuses, vous permettant de vous concentrer sur les acheteurs sérieux et d'accélérer votre vente.",
    },
    {
      result: 1,
      why: `Si la plupart des réponses sont des "B", vous pourriez dire`,
      explaination:
        "Vous avez déjà une idée générale de la vente immobilière, mais vous pourriez bénéficier de l'expertise et de l'accompagnement personnalisé de notre équipe de La Belle Offre pour maximiser la valeur de votre propriété et faciliter le processus de vente.",
    },
    {
      result: 2,
      why: `Si la plupart des réponses sont des "C", vous pourriez dire`,
      explaination:
        "Il semble que vous pourriez avoir besoin d'un peu d'aide pour naviguer dans le processus de vente immobilière. Notre équipe de La Belle Offre est là pour vous guider à chaque étape du processus et rendre la vente de votre bien aussi fluide que possible.",
    },
  ];

  const letters = ["A", "B", "C"];

  const [list, setList] = useState(questions);
  const [result, setResult] = useState(undefined);
  const [showErrors, setShowErrors] = useState(false);

  function onChooseAnswer(questionId, choiceIndex) {
    setList(
      list.map((q) => {
        if (q.id === questionId) {
          return { ...q, answer: choiceIndex };
        } else {
          return q;
        }
      }),
    );
  }
  function handleSubmit() {
    // vérifier qu'on a répondu à toutes les questions
    const isValid = list.filter((q) => q.answer === undefined).length === 0;
    if (!isValid) {
      setShowErrors(true);
      return;
    }

    // calculer le total de chq réponse (A, B ou C) et choisir le maximum.
    const countABCArray = [0, 0, 0];
    for (const item in list) {
      const element = list[item];
      countABCArray[element.answer]++;
    }

    const max = Math.max(countABCArray[0], countABCArray[1], countABCArray[2]);

    // résultat :
    const result = countABCArray.indexOf(max);

    setResult(result);
  }
  function QuizError() {
    return (
      <>
        <p className="my-2 rounded-md bg-white p-2 text-sm text-error shadow-sm">
          <i className="fa-solid fa-warning"></i>&nbsp;Veuillez choisir une
          réponse.
        </p>
      </>
    );
  }
  function QuizQuestion({ item }) {
    return (
      <div className="pt-6">
        <h2 className="pb-6 pt-4 text-2xl tracking-tight md:text-3xl">
          {item.id}. {item.text}
        </h2>
        <div className="divide-y-4 divide-transparent md:divide-y-[32px]">
          {showErrors && item.answer === undefined && <QuizError />}
          {item.choices.map((choice, i) => (
            <QuizChoice
              questionId={item.id}
              index={i}
              choice={choice}
              answer={item.answer}
              key={`quiz-question-${item.id}-choice-${i}`}
            />
          ))}
        </div>
      </div>
    );
  }
  function QuizChoice({ questionId, index, choice, answer }) {
    return (
      <p
        className="cursor-pointer pt-2 text-base font-light hover:font-normal md:text-lg"
        onClick={() => onChooseAnswer(questionId, index)}
      >
        <i
          className={twMerge(
            "fa-regular",
            answer === index
              ? "fa-check-circle font-normal text-green-600 "
              : "fa-circle",
          )}
        ></i>
        &nbsp;<span className="">{letters[index]}</span>.&nbsp;
        {choice}
      </p>
    );
  }
  function QuizProgress({ index }) {
    return (
      <>
        <div>
          <span id="ProgressLabel" className="sr-only">
            Progress
          </span>
          <span
            role="progressbar"
            aria-labelledby="ProgressLabel"
            aria-valuenow="75"
            className="relative block rounded-full bg-labelleoffre-tertiary-light"
          >
            <span className="absolute inset-0 flex items-center justify-center text-base">
              <span className="font-bold text-white">
                {index} sur {list.length}
              </span>
            </span>

            <span
              className="block h-6 rounded-full bg-labelleoffre-secondary text-center"
              style={{ width: (index / list.length) * 100 + "%" }}
            >
              {" "}
            </span>
          </span>
        </div>
      </>
    );
  }
  function QuizResults() {
    return (
      <>
        <h2 className="py-4 text-3xl tracking-tight md:py-8">
          Vos résultats du quiz
        </h2>
        <h2
          ref={ref}
          className="pb-4 text-2xl font-normal tracking-tight text-labelleoffre-tertiary"
        >
          Vous avez obtenu une majorité de réponses&nbsp;
          <b>{letters[result]}</b>
        </h2>
        {rules
          .filter((rule) => rule.result === result)
          .map((rule, i) => (
            <>
              <img
                className="my-4 rounded-xl object-cover shadow-sm md:w-[600px] md:shadow-md"
                src="/img/quiz/iStock-1400864454.jpg"
                alt="Homme heureux d'avoir terminé le quiz"
              />
              <p
                className="pt-4 text-lg font-light md:text-xl"
                key={`rule-result-${i}`}
              >
                {rule.explaination}
              </p>
            </>
          ))}
        <div className="text-center">
          <a
            className="mt-4 inline-flex max-w-xs items-center justify-center rounded-full bg-labelleoffre-secondary p-4 font-Josefin text-xl text-labelleoffre-white transition-colors hover:bg-labelleoffre-tertiary focus:outline-none focus:ring-1 focus:ring-offset-0"
            href="/#formules"
          >
            Voir les formules
          </a>
        </div>
      </>
    );
  }
  function QuizButton({ children, handler, isDisabled, className }) {
    return (
      <button
        className={twMerge(
          " mt-4 inline-flex max-w-xs items-center justify-center rounded-full bg-labelleoffre-secondary p-4 font-Josefin text-xl text-labelleoffre-white transition-colors hover:bg-labelleoffre-tertiary focus:outline-none focus:ring-1 focus:ring-offset-0",
          isDisabled === true
            ? "cursor-not-allowed bg-labelleoffre-tertiary-light text-slate-400 hover:bg-labelleoffre-tertiary-light"
            : "",
          className,
        )}
        disabled={isDisabled}
        onClick={handler}
      >
        {children}
      </button>
    );
  }
  function canGoForward(item) {
    return currentIndex < list.length - 1 && item.answer !== undefined;
  }
  function forward() {
    setCurrentIndex(currentIndex + 1);
  }
  function canGoBack() {
    return currentIndex > 0;
  }
  function back() {
    setCurrentIndex(currentIndex - 1);
  }
  function QuizNavigation({ item, showResults }) {
    return (
      <>
        <div className="mx-auto flex items-center justify-evenly gap-2 py-6 text-center">
          <QuizButton handler={back} isDisabled={!canGoBack()}>
            Précédent
          </QuizButton>
          <QuizButton handler={forward} isDisabled={!canGoForward(item)}>
            Suivant
          </QuizButton>
        </div>
        {showResults && (
          <div className="py-6 text-center">
            <QuizButton handler={handleSubmit} className="h-14 w-48 text-lg">
              Voir mes résultats
            </QuizButton>
          </div>
        )}
      </>
    );
  }

  const [currentIndex, setCurrentIndex] = useState(-1);

  function isStarted() {
    return currentIndex > -1;
  }
  function isAtEnd() {
    return currentIndex === list.length - 1;
  }
  function start() {
    setCurrentIndex(0);
  }
  const ref = useRef();
  const { createConfetti, canvasProps } = useConfetti({
    shapeWeights: {
      triangle: 2,
      circle: 2,
      square: 3,
      star: 1,
    },
    count: 250,
    diameter: [10, 30],
    duration: 700,
  });
  useEffect(() => {
    if (result !== undefined) {
      createConfetti({
        sourceRef: ref,
        spawnLocation: "area",
      });
    }
  }, [result, createConfetti]);

  function WelcomeScreen() {
    return (
      <>
        <h2 className="pb-4 font-light tracking-tighter text-labelleoffre-tertiary">
          Êtes-vous prêt à vendre seul&nbsp;?
        </h2>
        <p className="pb-4 text-lg font-light">
          Bienvenue dans notre quiz interactif pour déterminer si vous êtes prêt
          à vendre votre propriété seul ou si vous pourriez bénéficier de
          l'accompagnement de La Belle Offre.
        </p>
        <p className="pb-4 text-lg font-light">
          Répondez simplement aux questions ci-dessous pour découvrir vos
          besoins en matière de vente immobilière&nbsp;!
        </p>
        <img
          src="/img/quiz/mann-mit-buzzer_cc-by-20.jpg"
          alt="Homme devant un buzzer"
          className="mx-auto mb-8 size-60 rounded-full object-cover md:size-[300px]"
        />

        <div className="text-center">
          <QuizButton handler={start}>Démarrer le quiz</QuizButton>
        </div>
      </>
    );
  }
  function GameScreen() {
    return list
      .filter((x, i) => i === currentIndex)
      .map((item, i) => (
        <div key={`quiz-question-${i}`}>
          <QuizProgress index={currentIndex + 1} />
          <QuizQuestion item={item} />
          <QuizNavigation
            item={item}
            showResults={isAtEnd() && item.answer !== undefined}
          />
        </div>
      ));
  }

  return (
    <>
      <canvas {...canvasProps} />
      <section id="quiz" className="pt-20">
        <div className="mx-auto px-2 pb-20 pt-6 sm:max-w-screen-md">
          <h1 className="pb-4 text-center tracking-tighter text-labelleoffre-secondary">
            Quiz immobilier
          </h1>

          {!isStarted() && <WelcomeScreen />}
          {isStarted() && result === undefined && <GameScreen />}
          {result !== undefined && <QuizResults />}
        </div>
      </section>
    </>
  );
}
