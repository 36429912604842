import { twMerge } from "tailwind-merge";

export default function Card({
  icon,
  title,
  text,
  anim,
  className,
  big = false,
}) {
  return (
    <div
      className={twMerge(
        "group rounded-3xl bg-labelleoffre-tertiary-light/50 p-4 transition-colors hover:bg-labelleoffre-tertiary-light/80",
        big ? "p-4 md:p-8" : "p-2 md:p-4",
        className,
      )}
    >
      <p className="m-0 p-0 text-center text-4xl text-labelleoffre-tertiary md:text-7xl">
        <i
          className={twMerge(
            "fa-solid transition-transform duration-700 group-hover:scale-125",
            icon,
            anim,
          )}
        />
      </p>
      <h3
        className={twMerge(
          "pb-2 pt-2 text-center font-medium tracking-tighter text-labelleoffre-tertiary md:pt-4",
          big ? "text-4xl" : "text-xl",
        )}
      >
        {title}
      </h3>
      <p
        className={twMerge(
          "text-justify text-sm font-light",
          big ? "text-lg" : "text-sm",
        )}
      >
        {text}
      </p>
    </div>
  );
}
