import "./About.css";

export default function About() {
  function ContactButton() {
    return (
      <a
        className="inline-block w-full rounded-full bg-labelleoffre-secondary px-5 py-3 font-light  text-white transition-colors hover:bg-labelleoffre-tertiary sm:w-auto"
        href="/contact"
      >
        Contactez-moi
      </a>
    );
  }

  return (
    <section id="about">
      <div className="mx-auto max-w-screen-md px-4 pb-10 pt-32">
        <h1>A propos</h1>
        <h2 className="pb-4 text-2xl font-light">
          Réécrire les règles de l'immobilier ensemble avec La Belle Offre
        </h2>
        <img
          className="float-left mr-3 h-[200px] rounded-md object-scale-down shadow-md md:h-[400px]"
          src="/img/about/portrait-steven-quenea.jpg"
          alt="Portrait de Steven Quenea"
        />
        <p>
          Mon parcours d’investisseur et de marchand de biens m’a confronté à
          une réalité souvent méconnue : la solitude du vendeur immobilier. J’ai
          moi-même ressenti le besoin de vendre seul, non pas seulement pour
          économiser sur les frais d’agence, mais surtout par le désir de
          relever un défi personnel. Vendre efficacement sans l’aide d’une
          agence immobilière peut être non seulement gratifiant, mais aussi très
          rentable si l’on utilise les bons leviers.
        </p>
        <p>
          C’est cette conviction profonde qui a donné naissance à La Belle
          Offre. Mon objectif est de fournir aux vendeurs tous les outils et
          leviers nécessaires pour réussir leur vente, tout en les accompagnant
          comme leur coach personnel. Il s’agit avant tout d’une histoire
          humaine, où chaque individu est soutenu et guidé dans son projet
          immobilier.
        </p>
        <p>
          Pour les acheteurs, l’expérience n’est pas différente. Qui n’a jamais
          rêvé d’être accompagné et conseillé lors de l’achat d’un bien
          immobilier ? Chez La Belle Offre, je suis là pour les rassurer et les
          guider à chaque étape, de la recherche à l’achat. En créant cette
          communauté, nous évoluons ensemble, réalisant des économies tout en
          restant maîtres de nos transactions immobilières. Rejoignez-nous dans
          cette aventure où chaque membre se sent soutenu, informé et confiant
          dans ses décisions immobilières. Ensemble, nous réécrivons les règles
          du jeu immobilier, mettant l’humain au cœur de chaque transaction.
        </p>
        <p>
          Je suis Steven QUENEA, votre coach immobilier et fondateur de La Belle
          Offre. Passionné par l'immobilier, j'ai entamé ma carrière dans le
          secteur du Service Après-Vente automobile. De mécanicien à marchand de
          biens, mon parcours m'a doté d'une expertise solide, forgée sur le
          terrain à travers mes propres investissements. Aujourd'hui, ma mission
          est de redéfinir les règles de l'immobilier en offrant un
          accompagnement personnalisé à chaque étape, guidant ainsi vendeurs et
          acheteurs vers le succès immobilier.
        </p>
        <p>
          N'hésitez pas à me contacter pour découvrir comment je peux vous
          accompagner dans votre projet.
        </p>

        <div className="text-center text-lg ">
          <ContactButton />
        </div>
      </div>
    </section>
  );
}
